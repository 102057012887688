import { FC, ReactNode, SVGProps, useRef, useState } from 'react';
import { collapseElement } from '../../../lib/utils/animations';
import { CrossIcon } from '../../../assets/icons';
import cx from 'classnames';
import { InfoBannerTheme } from './InfoBanner.types';
import styles from './InfoBanner.module.scss';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export interface Props {
  title: string;
  content?: string;
  className?: string;
  children?: ReactNode;
  icon?: FC<SVGProps<SVGSVGElement>>;
  closeButton?: boolean;
  iconClassName?: string;
  theme?: InfoBannerTheme;
}

const InfoBanner: FC<Props> = ({
  title,
  content,
  className,
  children,
  icon: Icon = null,
  iconClassName,
  closeButton = true,
  theme = InfoBannerTheme.INFORMATIONAL,
}) => {
  const { t } = useTranslation();
  const [shouldRender, setShouldRender] = useState<boolean>(true);
  const infoBannerId = useRef<string>(uuid());
  const infoBannerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={infoBannerRef}
      id={infoBannerId.current}
      className={cx(className, styles['info-banner'], {
        [styles['info-banner--informational']]: theme === InfoBannerTheme.INFORMATIONAL,
        [styles['info-banner--danger']]: theme === InfoBannerTheme.DANGER,
      })}
    >
      {shouldRender ? (
        <div
          className={cx(styles['info-banner__padding'], {
            [styles['info-banner__padding--content']]: content,
          })}
        >
          <div className={cx(styles['info-banner__line-wrapper'])}>
            <div className={cx(styles['info-banner__icon-wrapper'])}>
              {Icon && (
                <Icon
                  className={cx(iconClassName, styles['info-banner__icon-left'], {
                    [styles['info-banner__icon-left--informational']]:
                      theme === InfoBannerTheme.INFORMATIONAL,
                    [styles['info-banner__icon-left--danger']]: theme === InfoBannerTheme.DANGER,
                  })}
                  aria-hidden="true"
                />
              )}
              <p className={cx(styles['info-banner__title'])}>{title}</p>
            </div>
            {closeButton && (
              <button
                className={cx(styles['info-banner__close-button'])}
                type="button"
                aria-label={t('general.close')}
                onClick={() => {
                  collapseElement(infoBannerRef.current as HTMLElement, () => {
                    setShouldRender(false);
                  });
                }}
              >
                <CrossIcon aria-hidden="true" />
              </button>
            )}
          </div>
          {content && (
            <p
              className={cx(styles['info-banner__content-text'], {
                [styles['info-banner__children']]: content,
              })}
            >
              {content}
            </p>
          )}
          {children && (
            <div className={cx({ [styles['info-banner__children']]: children })}>{children}</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

InfoBanner.displayName = 'InfoBanner';

export default InfoBanner;
