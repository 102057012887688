import { PillProps, PillSizes } from './Pill.types';

import cx from 'classnames';
import { ElementType } from 'react';
import { PolymorphicComponent } from '../../../lib/models/GenericComponent.model';
import styles from './Pill.module.scss';

const DEFAULT_ELEMENT = 'div';

const Pill = <C extends ElementType = typeof DEFAULT_ELEMENT>({
  as,
  className,
  color,
  icon: Icon,
  size = PillSizes.MD,
  text,
  ...props
}: PolymorphicComponent<C, PillProps>) => {
  const TagName = as || DEFAULT_ELEMENT;

  return (
    <TagName
      {...props}
      className={cx(className, styles['pill'], {
        [styles[`pill--${size}`]]: size,
        [styles[`pill--${color}`]]: color,
        [styles[`pill--clickable`]]: as === 'button',
      })}
    >
      {Icon && (
        <Icon className={cx(styles['pill__icon'], { [styles[`pill__icon--${size}`]]: size })} />
      )}
      <span className={cx(styles['pill__text'])}>{text}</span>
    </TagName>
  );
};

Pill.displayName = 'Pill';

export default Pill;
