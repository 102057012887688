import { BayerValueEastProvinces } from '../constants/forms';
import { GET_FARM_PROVINCE } from '../graphql/FarmInformation.gql';
import { GET_PROGRAM_REWARDS_BREAKDOWN_QUERY } from '../graphql/ProgramRewards.gql';
import { Region } from '../../__generated__/graphql';
import { useQuery } from '@apollo/client';

export const usePreferredRegion = (skip?: boolean) => {
  const { data: provinceData, loading: provinceDataLoading } = useQuery(GET_FARM_PROVINCE, {
    skip,
  });
  const { data: programData, loading: programDataLoading } = useQuery(
    GET_PROGRAM_REWARDS_BREAKDOWN_QUERY,
    {
      variables: { input: { year: new Date().getFullYear() } },
      skip,
    }
  );
  const normalizedProgram =
    programData?.enrolledProgramsInfo?.bayerValueProgramInfo?.program.name.toLocaleLowerCase();
  let programRegion;

  if (normalizedProgram?.includes(Region.East)) {
    programRegion = Region.East;
  } else if (normalizedProgram?.includes(Region.West)) {
    programRegion = Region.West;
  } else {
    programRegion =
      provinceData?.farm.farmInfo.address?.province &&
      BayerValueEastProvinces.includes(provinceData.farm.farmInfo.address.province)
        ? Region.East
        : Region.West;
  }

  return { programRegion, isLoading: programDataLoading || provinceDataLoading };
};
