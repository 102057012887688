import { CalculatorStep, Step } from './stepper';
import Preferences from '../../pages/BayerRewardsCalculator/steps/Preferences';
import ProductSelection from '../../pages/BayerRewardsCalculator/steps/ProductSelection';
import Results from '../../pages/BayerRewardsCalculator/steps/Results';
import SavedCalculations from '../../pages/BayerRewardsCalculator/steps/SavedCalculations';
import SplashScreen from '../../pages/BayerRewardsCalculator/steps/SplashScreen/SplashScreen';

export const mockTotalRewardsEarned = 'XXXX.XX';
export const mockCashbackPercentage = 'XX';

export type RewardDataExists = {
  breakdownData: boolean;
  purchasesData: boolean;
};

export const CALCULATOR_STEPS: Step[] = [
  {
    id: CalculatorStep.INSTRUCTIONS,
    labelKey: 'bayer-rewards-calculator.steps.preferences',
    index: 1,
    Component: SplashScreen,
  },
  {
    id: CalculatorStep.PREFERENCES,
    labelKey: 'bayer-rewards-calculator.steps.preferences',
    index: 2,
    Component: Preferences,
  },
  {
    id: CalculatorStep.SELECT_PRODUCTS,
    labelKey: 'bayer-rewards-calculator.steps.select-products',
    index: 3,
    Component: ProductSelection,
  },
  {
    id: CalculatorStep.RESULTS,
    labelKey: 'bayer-rewards-calculator.steps.results',
    index: 4,
    Component: Results,
  },
  {
    id: CalculatorStep.SAVED_CALCULATIONS,
    labelKey: 'bayer-rewards-calculator.saved-calculations',
    index: 5,
    Component: SavedCalculations,
  },
];
