import {
  CalculatorContextAction,
  CalculatorContextActionTypes,
  CalculatorContextState,
} from './CalculatorContext.types';
import { PreFillOption } from '../../types/calculator';
import { updateRewardsAndCost } from '../../utils/calculator';

const CalculatorContextReducer = (
  state: CalculatorContextState,
  action: CalculatorContextAction
): CalculatorContextState => {
  switch (action.type) {
    case CalculatorContextActionTypes.UpdatePreferences:
      return {
        ...state,
        preFill: action.payload.prefill,
        selectedCrops: action.payload.selectedCrops,
      };
    case CalculatorContextActionTypes.AddSelectedProduct:
      return updateRewardsAndCost({
        ...state,
        selectedProducts: [...state.selectedProducts, action.payload],
      });
    case CalculatorContextActionTypes.LoadSavedProducts:
      return updateRewardsAndCost({
        ...state,
        ...action.payload,
      });
    case CalculatorContextActionTypes.LoadSavedCrops:
      return {
        ...state,
        selectedCrops: action.payload,
      };
    case CalculatorContextActionTypes.LoadSavedCalculation:
      return updateRewardsAndCost({
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        lastUpdated: action.payload.date,
        selectedCrops: action.payload.crops,
        selectedProducts: action.payload.products,
        isSavedCalculation: true,
      });
    case CalculatorContextActionTypes.UpdateSelectedProduct:
      return updateRewardsAndCost({
        ...state,
        selectedProducts: state.selectedProducts.map((product, idx) =>
          idx === action.payload.index ? { ...product, ...action.payload.product } : product
        ),
      });
    case CalculatorContextActionTypes.RemoveSelectedProduct:
      return updateRewardsAndCost({
        ...state,
        selectedProducts: state.selectedProducts.filter((_, idx) => idx !== action.payload.index),
      });
    case CalculatorContextActionTypes.UpdateProductQualifications:
      return {
        ...state,
        productQualifications: action.payload.productQualifications,
      };
    case CalculatorContextActionTypes.ResetCalculator:
      return {
        ...state,
        id: undefined,
        name: undefined,
        lastUpdated: undefined,
        preFill: PreFillOption.NO,
        selectedCrops: [],
        selectedProducts: [],
        totalRewards: 0,
        breakdown: {},
        totalCost: 0,
        productQualifications: [],
        isSavedCalculation: false,
        missingProducts: false,
      };
    case CalculatorContextActionTypes.ResetSelectedProduct:
      return {
        ...state,
        totalCost: 0,
        totalRewards: 0,
        selectedProducts: [],
      };
    case CalculatorContextActionTypes.UpdateProgramRegion:
      return {
        ...state,
        region: action.payload,
      };
    case CalculatorContextActionTypes.UpdateCalculationName:
      return {
        ...state,
        name: action.payload.name,
      };
    case CalculatorContextActionTypes.UpdateCalculation:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        lastUpdated: action.payload.date,
      };
    default:
      return state;
  }
};

export default CalculatorContextReducer;
