import {
  CalculatorPreferencesForm,
  PreFillOption,
  SelectedProduct,
} from '../../../../lib/types/calculator';
import { Controller, useForm } from 'react-hook-form';
import { FC, useEffect, useId, useState } from 'react';
import {
  GET_PREFILL_PRODUCTS,
  GET_PRODUCT_QUALIFICATIONS,
} from '../../../../lib/graphql/Calculator.gql';

import { CalculatorContextActionTypes } from '../../../../lib/contexts/calculator/CalculatorContext.types';
import Checkbox from '../../../../components/_shared/Checkbox';
import { cropList } from '../../../../lib/constants/calculator';
import cx from 'classnames';
import Radio from '../../../../components/_shared/Radio';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import Spinner from '../../../../components/_shared/Spinner';
import { type StepComponentProps } from '../../../../lib/constants/stepper';
import StepsWrapper from '../../../Onboarding/StepsWrapper';
import styles from '../../BayerRewardsCalculator.module.scss';
import useCalculatorContext from '../../../../lib/contexts/calculator/useCalculatorContext';
import { useLazyQuery } from '@apollo/client';
import { usePreferredRegion } from '../../../../lib/hooks/usePreferredRegion';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

const Preferences: FC<StepComponentProps> = ({ back, next }: StepComponentProps) => {
  const formId = useId();
  const { t } = useTranslation();
  const [preferences, dispatch] = useCalculatorContext();
  const { programRegion, isLoading: isRegionLoading } = usePreferredRegion();
  const [loadingPreferences, setLoadingPreferences] = useState<boolean>(false);
  const [, dispatchSnackbar] = useSnackbarContext();
  const [getPrefillProducts, { loading: loadingPrefillData }] = useLazyQuery(GET_PREFILL_PRODUCTS);
  const [getCalculatorQualifications, { loading: loadingQualifications }] = useLazyQuery(
    GET_PRODUCT_QUALIFICATIONS
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
    getValues,
  } = useForm<CalculatorPreferencesForm>({
    defaultValues: {
      preFillForm: PreFillOption.NO,
    },
  });

  useEffect(() => {
    if (!isRegionLoading) {
      dispatch({
        type: CalculatorContextActionTypes.UpdateProgramRegion,
        payload: programRegion,
      });
    }
  }, [isRegionLoading]);

  useEffect(() => {
    if (preferences.preFill && preferences.selectedCrops) {
      reset({
        preFillForm: preferences.preFill,
        crops: preferences.selectedCrops,
      });
    }
  }, [preferences]);

  const renderCheckboxes = () =>
    cropList(t)
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((crop) => (
        <li key={crop.value}>
          <Checkbox
            {...register('crops', {
              required: t('form.errors.required'),
            })}
            label={crop.label}
            value={crop.value}
            image={crop.image}
            hasError={!!errors.crops}
          />
        </li>
      ));

  const onSubmit = () => {
    const formValues = getValues();

    dispatch({
      type: CalculatorContextActionTypes.UpdatePreferences,
      payload: {
        prefill: formValues.preFillForm,
        selectedCrops: formValues.crops,
      },
    });

    scrollTo({ top: 0, behavior: 'smooth' });
    next?.();
  };

  const handlePrefill = async (onChange: (option: PreFillOption) => void) => {
    setLoadingPreferences(true);

    Promise.all([
      getCalculatorQualifications({ variables: { input: { region: programRegion } } }),
      getPrefillProducts({
        variables: { input: { region: programRegion } },
      }),
    ])
      .then(([qualificationsResponse, prefillProductsResponse]) => {
        if (qualificationsResponse?.data) {
          dispatch({
            type: CalculatorContextActionTypes.UpdateProductQualifications,
            payload: {
              productQualifications: qualificationsResponse.data.productQualifications || [],
            },
          });
        }
        if (qualificationsResponse.data) {
          dispatch({
            type: CalculatorContextActionTypes.UpdatePreferences,
            payload: {
              prefill: PreFillOption.YES,
              selectedCrops: prefillProductsResponse.data?.prefillProducts.selectedCrops ?? [],
            },
          });
          dispatch({
            type: CalculatorContextActionTypes.LoadSavedProducts,
            payload: {
              selectedProducts: (prefillProductsResponse.data?.prefillProducts.selectedProducts ??
                []) as SelectedProduct[],
              missingProducts:
                prefillProductsResponse.data?.prefillProducts.missingProducts ?? false,
            },
          });
        }

        onChange(PreFillOption.YES);

        setLoadingPreferences(false);
      })
      .catch(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: { label: t('errors.generic'), state: SnackbarStates.WARNING },
        });
      });
  };

  const handleReset = (onChange: (option: PreFillOption) => void) => {
    dispatch({
      type: CalculatorContextActionTypes.ResetCalculator,
    });

    onChange(PreFillOption.NO);
  };

  return (
    <StepsWrapper
      title={t('bayer-rewards-calculator.steps.preferences')}
      subTitle={t('bayer-rewards-calculator.preferences.description')}
      back={{ text: t('general.back'), onClick: back }}
      submit={{
        text: t('general.next'),
        buttonFormId: formId,
        buttonType: 'submit',
      }}
      classNames={{
        wrapper: cx(styles['calculator__step-wrapper']),
        form: cx(styles['calculator__form-wrapper']),
      }}
    >
      <hr className={cx(styles['calculator__divider'])} />
      {loadingPrefillData || loadingQualifications || loadingPreferences || isRegionLoading ? (
        <div
          className={cx(
            styles['calculator__spinner-wrapper'],
            styles['calculator__spinner-wrapper--preferences']
          )}
        >
          <Spinner />
        </div>
      ) : (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <div className={cx(styles['calculator__section'])}>
            <h2 className={cx(styles['calculator__sub-title'])}>
              {t('bayer-rewards-calculator.preferences.prefill.heading')}
            </h2>
            <p className={cx(styles['calculator__text'])}>
              {t('bayer-rewards-calculator.preferences.prefill.description')}
            </p>
            <Controller
              control={control}
              name="preFillForm"
              rules={{ required: t('form.errors.required') }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  <Radio
                    className={cx(styles['filter-modal__radio'])}
                    label={t('general.yes')}
                    value={PreFillOption.YES}
                    checked={value === PreFillOption.YES}
                    name={name}
                    onChange={() => handlePrefill(onChange)}
                  />
                  <Radio
                    className={cx(styles['filter-modal__radio'])}
                    label={t('general.no')}
                    value={PreFillOption.NO}
                    checked={value === PreFillOption.NO}
                    name={name}
                    onChange={() => handleReset(onChange)}
                  />
                </div>
              )}
            />
          </div>
          <hr className={cx(styles['calculator__divider'])} />

          <div className={cx(styles['calculator__section'])}>
            <h2 className={cx(styles['calculator__sub-title'])}>
              {t('bayer-rewards-calculator.preferences.select-crops.heading')}
            </h2>
            <p className={cx(styles['calculator__text'])}>
              {t('bayer-rewards-calculator.preferences.select-crops.description')}
            </p>
            <ul className={cx(styles['calculator__crop-list'])}>{renderCheckboxes()}</ul>
            {errors.crops && (
              <p className={cx(styles['calculator__error-message'])}>{errors.crops.message}</p>
            )}
          </div>
        </form>
      )}
    </StepsWrapper>
  );
};

Preferences.displayName = 'Preferences';

export default Preferences;
