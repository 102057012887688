import { CalculatorCategory, CropType, ProductMainGroup } from '../types/calculator';

import canolaImage from '../../assets/images/crops/canola.jpeg';
import cerealImage from '../../assets/images/crops/cereal.jpeg';
import cornImage from '../../assets/images/crops/corn.jpeg';
import fruitImage from '../../assets/images/crops/fruits.jpeg';
import oilseedImage from '../../assets/images/crops/oilseed.jpeg';
import pulseImage from '../../assets/images/crops/pulse.jpeg';
import soybeanImage from '../../assets/images/crops/soybean.jpeg';
import { TFunction } from 'i18next';

export const BASE_PRODUCT_CATEGORIES_ARRAY = [
  {
    key: ProductMainGroup.SEED_TREATMENT,
    title: 'bayer-rewards-calculator.select-products.categories.seed-treatment.title',
    subtitle: 'bayer-rewards-calculator.select-products.categories.seed-treatment.subtitle',
    products: [],
  },
  {
    key: ProductMainGroup.HERBICIDES,
    title: 'bayer-rewards-calculator.select-products.categories.herbicide.title',
    subtitle: 'bayer-rewards-calculator.select-products.categories.herbicide.subtitle',
    products: [],
  },
  {
    key: ProductMainGroup.FUNGICIDES,
    title: 'bayer-rewards-calculator.select-products.categories.fungicide.title',
    subtitle: 'bayer-rewards-calculator.select-products.categories.fungicide.subtitle',
    products: [],
  },
];

export const FULL_PRODUCT_CATEGORIES_ARRAY: CalculatorCategory[] = [
  ...BASE_PRODUCT_CATEGORIES_ARRAY,
  {
    key: ProductMainGroup.TRAIT,
    title: 'bayer-rewards-calculator.select-products.categories.traits.title',
    subtitle: 'bayer-rewards-calculator.select-products.categories.traits.subtitle',
    products: [],
  },
];

export const cropList = (t: TFunction) => [
  {
    label: t('crops.CANOLAANDOILSEED'),
    value: CropType.CANOLA,
    image: canolaImage,
  },
  {
    label: t('crops.CORN'),
    value: CropType.CORN,
    image: cornImage,
  },
  {
    label: t('crops.OTHERBEAN'),
    value: CropType.PULSES,
    image: pulseImage,
  },
  {
    label: t('crops.SOYBEANS'),
    value: CropType.SOYBEANS,
    image: soybeanImage,
  },
  {
    label: t('crops.CEREALS'),
    value: CropType.CEREALS,
    image: cerealImage,
  },
  {
    label: t('crops.FRUITSANDVEG'),
    value: CropType.FRUIT_AND_VEGETABLE,
    image: fruitImage,
  },
];

export const expandedCropList = (t: TFunction) => [
  ...cropList(t),
  {
    label: t('crops.OILSEEDS'),
    value: CropType.OILSEEDS,
    image: oilseedImage,
  },
];

export const calculatorGroupOrder = [
  {
    index: 0,
    groupKey: ProductMainGroup.SEED_TREATMENT,
  },
  {
    index: 1,
    groupKey: ProductMainGroup.HERBICIDES,
  },
  {
    index: 2,
    groupKey: ProductMainGroup.TRAIT,
  },
  {
    index: 3,
    groupKey: ProductMainGroup.FUNGICIDES,
  },
  {
    index: 4,
    groupKey: ProductMainGroup.INSECTICIDE,
  },
];
