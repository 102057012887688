import { FC } from 'react';
import { UserOnboardingDataQuery } from '../../__generated__/graphql';

export interface StepComponentProps {
  next?: () => void;
  back?: () => void;
  goToStep?: (stepId: string) => void;
}

export interface Step {
  id: string;
  labelKey: string;
  children?: Step[];
  isCompleted?: boolean;
  isInProgress?: boolean;
  index?: number; // step number
  shouldBeDisplayed?: (onboardingData: UserOnboardingDataQuery | null) => boolean;
  Component?: FC<StepComponentProps>;
}

export enum OnboardingStep {
  MY_ACCOUNT = 'my-account',
  PROFILE_DETAILS = 'profile-details',
  MY_OPERATION = 'my-operation',
  FARM_INFORMATION = 'farm-information',
  PREFERRED_RETAILERS = 'preferred-retailers',
  USER_INVITATIONS = 'user-invitations',
  PARTNER_PERMISSIONS = 'partner-permissions',
  USER_PERMISSIONS = 'user-permissions',
  SUMMARY = 'summary',
  BAYER_VALUE_REWARDS = 'bayer-value-rewards',
  CROP_INFORMATION = 'crop-information',
}

export enum CalculatorStep {
  INSTRUCTIONS = 'calculator-instructions',
  SAVED_CALCULATIONS = 'calculator-saved-calculations',
  SELECT_PRODUCTS = 'calculator-select-products',
  PREFERENCES = 'calculator-preferences',
  RESULTS = 'calculator-results',
}

export enum SignUpStep {
  PRIMARY_OWNER = 'primary-owner',
  VERIFICATION_METHOD = 'verification-method',
  PHONE_VERIFICATION = 'phone-verification',
  COMPLETE_ACCOUNT = 'complete-account',
  SIGN_UP_COMPLETE = 'sign-up-complete',
}
