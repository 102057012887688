export enum QueryParams {
  TOKEN = 'token',
  GIGYA_CODE = 'code',
  GIGYA_MODE = 'mode',
  UID = 'uid',
}

export enum PageRoutes {
  // Dashboard
  HOME = '/',

  // Onboarding
  LOGIN = 'login',
  FORGOT_PASSWORD = 'login/forgot-password',
  SIGNUP = 'sign-up',
  INSCRIPTION = 'inscription',
  ONBOARDING = 'onboarding',
  GETTING_STARTED = 'getting-started',
  SETUP = 'setup',

  // Gigya
  GIGYA_LOGIN = '/login/gigya',
  GIGYA_PROXY_PAGE = '/login',
  GIGYA_ERROR = 'login/error',
  LOGIN_ERROR = '/error',

  // BayerValue Rewards
  REWARDS = 'bayer-value',
  PROGRAMS = '/programs',
  BAYER_VALUE_PROGRAM = 'programs/bayer-value',
  OTHER_PROGRAMS = 'programs/other',
  CALCULATOR = '/calculator',

  // Order And Purchases
  ORDER_PURCHASE_HISTORY = 'order-and-purchase-history',
  PRE_ORDERS = 'pre-orders',

  // Account Settings
  ACCOUNT_SETTINGS = 'account-settings',
  SETTINGS_PROFILE = 'profile',
  SETTINGS_MY_OPERATION = 'operation',
  SETTINGS_MY_PREFERRED_RETAILER = 'retailers',
  SETTINGS_USER_PERMISSION = 'users',
  SETTINGS_SECURITY = 'security',
  RESET_PASSWORD_CONFIRMATION = 'reset-password-complete',

  // Data Visualization
  DATA_VISUALIZATION = 'data-visualization',

  // FAQ
  FAQ = 'faq',

  // Page not found
  NOT_FOUND = 'not-found',

  // Analytics
  SIGNUP_ANALYTICS = 'sign-up/fr?utm_source=vanity_redirect&utm_medium=dm&utm_campaign=mybayer-launch&utm_content=fr',

  // Custom email verification screen
  EMAIL_VERIFIED = 'email-verified',
}

/*
 * List of routes using Apollo Client's "cache-first" and "cache-only" fetch policies. Other routes
 * not included in this list will use "cache-first" when the user is authenticated and
 * "cache-only" when they are not.
 */
export const cacheFirstRoutes = [
  PageRoutes.ACCOUNT_SETTINGS,
  PageRoutes.BAYER_VALUE_PROGRAM,
  PageRoutes.FAQ,
  PageRoutes.GETTING_STARTED,
  PageRoutes.ONBOARDING,
  PageRoutes.ORDER_PURCHASE_HISTORY,
  PageRoutes.OTHER_PROGRAMS,
  PageRoutes.PRE_ORDERS,
  PageRoutes.REWARDS,
  PageRoutes.SETUP,
];

export enum LinkUrls {
  BAYER_VALUE_CALCULATOR_EAST = 'https://www.cropscience.bayer.ca/grower-programs/bayervalue-east',
  BAYER_VALUE_CALCULATOR_WEST = 'https://www.cropscience.bayer.ca/grower-programs/bayervalue-west',
  BAYER_VALUE_CALCULATOR_EAST_2023 = 'https://www.cropscience.bayer.ca/-/media/Bayer-CropScience/Country-Canada-Internet/Grower-Programs/BayerValue-2023/2023/2023-BayerValue-East-Calc',
  BAYER_VALUE_CALCULATOR_HP_2023 = 'https://www.cropscience.bayer.ca/-/media/Bayer-CropScience/Country-Canada-Internet/Grower-Programs/BayerValue-2023/2023/2023-BayerValue-HP-Calc',
  BAYER_VALUE_CALCULATOR_WEST_2023 = 'https://www.cropscience.bayer.ca/-/media/Bayer-CropScience/Country-Canada-Internet/Grower-Programs/BayerValue-2023/2023/2023-BayerValue-West-Calc',
  BAYER_VALUE_CONDITIONS_EAST = 'https://www.cropscience.bayer.ca/grower-programs/bayervalue-east#accordion_2',
  BAYER_VALUE_CONDITIONS_WEST = 'https://www.cropscience.bayer.ca/grower-programs/bayervalue-west#accordion_2',
  BAYER_VALUE_HOT_POTATOES = 'https://www.cropscience.bayer.ca/en/grower-programs/hot-potatoes',
  BAYER_VALUE_PROGRAMS = 'https://www.cropscience.bayer.ca/grower-programs',
  BAYER_VALUE_RULES_EAST = 'https://www.cropscience.bayer.ca/Grower-Programs/BayerValue-East#accordion_1',
  BAYER_VALUE_RULES_WEST = 'https://www.cropscience.bayer.ca/Grower-Programs/BayerValue-West#accordion_1',
  CATALOG_URL = 'https://www.cropscience.bayer.ca/products',
  CLIMATE_FIELD_VIEW_EAST = 'https://www.cropscience.bayer.ca/grower-programs/climate-fieldview-offers-east',
  CLIMATE_FIELD_VIEW_WEST = 'https://www.cropscience.bayer.ca/grower-programs/climate-fieldview-offers-west',
  CONDITIONS_OF_USE = 'https://www.cropscience.bayer.ca/General-Terms-of-Use',
  COOKIE_SETTINGS = 'https://www.cropscience.bayer.ca/en/Privacy-Statement#CookieSettings',
  CROP_SCIENCE_CANADA = 'https://www.cropscience.bayer.ca',
  GROWER_PROGRAMS = 'http://growerprograms.ca',
  MAIL_REBATE_FULFILLMENT = 'mailto:rebate.fulfillment.ca@bayercropscience.com',
  MAIL_SUPPORT_EAST = 'mailto:askus@bayercropscience.ca',
  MAIL_SUPPORT_WEST = 'mailto:info@bayercropscience.ca',
  PHONE_FIELD_VIEW = 'tel:1-888-924-7475',
  PHONE_REBATE_FULFILLMENT_TEL = 'tel:1-888-283-6847',
  PLACE_PRE_ORDER_URL = 'https://www.cropscience.bayer.ca/products/dekalb-seed',
  PREPEND_SLASH = '/', // Used to avoid relative routing (url concatenation )
  PRIVACY_STATEMENT = 'https://www.cropscience.bayer.ca/Privacy-Statement',
  SUMMER_SIZZLER_URL = 'https://www.cropscience.bayer.ca/en/grower-programs/dekalb-rewards',
  PAYMENT_EXCHANGE = 'https://b2bportal.citipaymentexchange.citi.com/enroll/BayerCropscience-ACH',
}

export enum WestCalculatorUrls {
  BAYER_VALUE = 'https://www.cropscience.bayer.ca/grower-programs/bayervalue-west',
  EMESTO = 'https://www.cropscience.bayer.ca/en/grower-programs/hot-potatoes-prairies',
  INCREDIBLE_BAYER_OFFER = 'https://www.cropscience.bayer.ca/en/grower-programs/bayervalue-ibo',
}

export const MailToLink = (email: string | undefined) => `mailto:${email}`;
export const TelephoneLink = (phone: string | undefined) => `tel:${phone}`;
