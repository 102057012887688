import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TooltipPosition, TooltipProps, TooltipTheme } from './Tooltip.types';
import cx from 'classnames';
import styles from './Tooltip.module.scss';
import { v4 as uuid } from 'uuid';

const WIDTH_OFFSET = 32;

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      children,
      id,
      position = TooltipPosition.RIGHT,
      heading,
      text,
      className,
      innerClassName,
      forceShow,
      showOnHover = true,
      showOnFocus = true,
      theme = TooltipTheme.DARK,
    },
    ref
  ) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(forceShow || false);
    const [shouldRender, setShouldRender] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [innerPosition, setInnerPosition] = useState<TooltipPosition | null>(null);
    const tooltipId = useRef<string>(id || uuid());
    const tooltipRef = useRef<HTMLDivElement>(null);

    const showTooltip = () => {
      setIsTooltipVisible(true);
      setShouldRender(true);
    };

    const hideTooltip = () => {
      setIsTooltipVisible(false);
    };

    useEffect(() => {
      if (forceShow) {
        showTooltip();
      } else {
        hideTooltip();
      }
    }, [forceShow]);

    useLayoutEffect(() => {
      if (shouldRender && isTooltipVisible && tooltipRef.current) {
        const tooltip = tooltipRef.current;
        const rect = tooltip.getBoundingClientRect();
        const leftPosition = rect.left;
        const rightPosition = rect.width + leftPosition;
        const windowWidth = window.innerWidth;

        if (rightPosition >= windowWidth) {
          setInnerPosition(TooltipPosition.LEFT);
        } else if (leftPosition < 0) {
          setInnerPosition(TooltipPosition.RIGHT);
        }
      }
    }, [shouldRender, isTooltipVisible]);

    const checkWidth = () => {
      if (isTooltipVisible && tooltipRef.current) {
        const tooltip = tooltipRef.current;
        const rect = tooltip.getBoundingClientRect();

        if (rect.x < 0) {
          tooltip.style.maxWidth = `${rect.width + rect.x - WIDTH_OFFSET}px `;
        } else if (rect.x + rect.width > window.innerWidth) {
          tooltip.style.maxWidth = `${window.innerWidth - rect.x - WIDTH_OFFSET}px `;
        }
      }
    };

    return (
      <div
        ref={ref}
        className={cx(
          styles['tooltip__wrapper'],
          styles[`tooltip__wrapper--${theme}`],
          styles[innerPosition || position],
          {
            [styles['tooltip__wrapper--visible']]: isTooltipVisible,
          },
          className
        )}
        role="button"
        tabIndex={0}
        onMouseEnter={() => {
          if (showOnHover) {
            showTooltip();
          }
        }}
        onMouseLeave={() => {
          if (!isFocused && showOnHover) {
            hideTooltip();
          }
        }}
        onFocus={() => {
          if (showOnFocus) {
            setIsFocused(true);
            showTooltip();
          }
        }}
        onBlur={() => {
          if (showOnFocus) {
            setIsFocused(false);
            hideTooltip();
          }
        }}
        aria-label={text}
      >
        {children}
        {shouldRender && (
          <div
            id={tooltipId.current}
            className={cx(
              styles.tooltip,
              styles[`tooltip--${theme}`],
              styles[innerPosition || position],
              {
                [styles['tooltip--visible']]: isTooltipVisible,
              },
              innerClassName
            )}
            onAnimationStart={checkWidth}
            role="tooltip"
            ref={tooltipRef}
          >
            {heading && <p className={cx(styles['tooltip__heading'])}>{heading}</p>}
            <p className={cx(styles['tooltip__text'])}>{text}</p>
          </div>
        )}
      </div>
    );
  }
);

export default Tooltip;
