import { ArcElement, Chart, Tooltip, TooltipModel } from 'chart.js';
import { ArcWidth, ChartEntry } from './ProgramDoughnutChart.types';
import { FC, useEffect, useRef, useState } from 'react';
import {
  formatChartData,
  getArcPercentage,
  getEmptyChartData,
  getTotalPrice,
} from './ProgramDoughnutChart.utils';

import ChartTooltip from './ChartTooltip';
import cx from 'classnames';
import { Doughnut } from 'react-chartjs-2';
import styles from './ProgramDoughnutChart.module.scss';
import { useTranslation } from 'react-i18next';

Chart.register(ArcElement, Tooltip);

export interface Props {
  arcWidth?: ArcWidth;
  data: ChartEntry[];
  label?: string;
  showText?: boolean;
}

const ProgramDoughnutChart: FC<Props> = ({
  label,
  showText = true,
  arcWidth = ArcWidth.MD,
  data,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const chartRef = useRef();
  const [totalPrice, setTotalPrice] = useState<number>(getTotalPrice(data));
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipData, setTooltipData] = useState<TooltipModel<'doughnut'> | null>(null);
  const [tooltipPos, setTooltipPos] = useState<{ top: number; left: number } | null>(null);

  useEffect(() => {
    setTotalPrice(getTotalPrice(data));
  }, [data]);

  const handleCustomTooltip = (context: any) => {
    if (context.tooltip.opacity === 0) {
      setTooltipVisible(false);
      return;
    }

    const chart = chartRef.current;
    if (chart) {
      const { canvas } = chart;
      if (canvas) {
        setTooltipVisible(true);

        const left = context.tooltip.x;
        const top = context.tooltip.y;

        if (tooltipPos?.top !== top || tooltipPos?.left !== left) {
          setTooltipPos({ top, left });
          setTooltipData(context.tooltip);
        }
      }
    }
  };

  const NO_TOOLTIP_PLUGIN = {
    enabled: false,
  };

  const TOOLTIP_PLUGIN = {
    ...NO_TOOLTIP_PLUGIN,
    external: handleCustomTooltip,
    callbacks: {
      label: (context: any) =>
        t('chart.tooltip', {
          programName: context.label,
          amount: context.raw,
          percentage: getArcPercentage(
            typeof context.raw === 'number' ? context.raw : 0,
            totalPrice,
            language
          ),
        }), // Needed for the tooltip to have the correct `x` position
    },
  };

  return (
    <div className={cx(styles['doughnut-chart'])}>
      <Doughnut
        ref={chartRef}
        data={totalPrice ? formatChartData(data) : getEmptyChartData()}
        options={{
          cutout: arcWidth,
          plugins: {
            legend: { display: false },
            tooltip: totalPrice ? TOOLTIP_PLUGIN : NO_TOOLTIP_PLUGIN,
          },
        }}
        aria-label={t('chart.alt')}
      />
      {showText && (
        <div className={cx(styles['doughnut-chart__text-wrapper'])}>
          <p className={cx(styles['doughnut-chart__label'])}>{label}</p>

          <p className={cx(styles['doughnut-chart__price'])}>
            {t('format.price', { value: totalPrice })}
          </p>
        </div>
      )}
      {tooltipPos && (
        <ChartTooltip
          data={tooltipData}
          position={tooltipPos}
          visibility={tooltipVisible}
          total={totalPrice}
        />
      )}
    </div>
  );
};

ProgramDoughnutChart.displayName = 'ProgramDoughnutChart';

export default ProgramDoughnutChart;
