import { PreFillOption, RewardsBreakdown, SelectedProduct } from '../../types/calculator';
import { ProductQualification, Region } from '../../../__generated__/graphql';
import { ReactNode } from 'react';

export interface CalculatorContextState {
  breakdown: RewardsBreakdown;
  id?: string;
  isSavedCalculation: boolean;
  lastUpdated?: Date;
  missingProducts?: boolean;
  name?: string;
  preFill: PreFillOption;
  productQualifications: ProductQualification[];
  region?: Region;
  selectedCrops: string[];
  selectedProducts: SelectedProduct[];
  totalCost: number;
  totalRewards: number;
}

export interface CalculatorContextProps {
  children: ReactNode;
}

export enum CalculatorContextActionTypes {
  AddSelectedProduct = 'AddSelectedProduct',
  LoadSavedCalculation = 'LoadSavedCalculation',
  LoadSavedCrops = 'LoadSavedCrops',
  LoadSavedProducts = 'LoadSavedProducts',
  RemoveSelectedProduct = 'RemoveSelectedProduct',
  ResetCalculator = 'ResetCalculator',
  ResetSelectedProduct = 'ResetSelectedProduct',
  UpdateCalculation = 'UpdateCalculation',
  UpdateCalculationName = 'UpdateCalculationName',
  UpdatePreferences = 'UpdatePreferences',
  UpdateProductQualifications = 'UpdateProductQualifications',
  UpdateProgramRegion = 'UpdateProgramRegion',
  UpdateSelectedProduct = 'UpdateSelectedProduct',
}

export type CalculatorContextAction =
  | {
      type: CalculatorContextActionTypes.AddSelectedProduct;
      payload: SelectedProduct;
    }
  | {
      type: CalculatorContextActionTypes.LoadSavedCrops;
      payload: string[];
    }
  | {
      type: CalculatorContextActionTypes.LoadSavedProducts;
      payload: { selectedProducts: SelectedProduct[]; missingProducts?: boolean };
    }
  | {
      type: CalculatorContextActionTypes.LoadSavedCalculation;
      payload: {
        id: string;
        name: string;
        date: Date;
        crops: string[];
        products: SelectedProduct[];
      };
    }
  | {
      type: CalculatorContextActionTypes.RemoveSelectedProduct;
      payload: { index: number };
    }
  | {
      type: CalculatorContextActionTypes.ResetCalculator;
    }
  | {
      type: CalculatorContextActionTypes.ResetSelectedProduct;
    }
  | {
      type: CalculatorContextActionTypes.UpdatePreferences;
      payload: {
        prefill: PreFillOption;
        selectedCrops: string[];
      };
    }
  | {
      type: CalculatorContextActionTypes.UpdateSelectedProduct;
      payload: {
        index: number;
        product: Partial<SelectedProduct>;
      };
    }
  | {
      type: CalculatorContextActionTypes.UpdateProductQualifications;
      payload: { productQualifications: ProductQualification[] };
    }
  | {
      type: CalculatorContextActionTypes.UpdateProgramRegion;
      payload: Region;
    }
  | {
      type: CalculatorContextActionTypes.UpdateCalculationName;
      payload: {
        name: string;
      };
    }
  | {
      type: CalculatorContextActionTypes.UpdateCalculation;
      payload: {
        id: string;
        name: string;
        date: Date;
      };
    };

export type CalculatorContextDispatch = (action: CalculatorContextAction) => void;

export type CalculatorContextType = [CalculatorContextState, CalculatorContextDispatch];
