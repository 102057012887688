import { CalculationRule, RewardProduct } from '../../../lib/types/calculator';

// 2025 BayerValue East
export enum BayerValueEastRuleId {
  SEED_TRAIT_MATCH_REWARDS = 'eastSeedTraitMatchRewards',
  UNMATCHED_CP_ACRES_REWARDS = 'eastUnmatchedCPRewards',
  FIELD_VIEW = 'eastFieldViewRewards',
  ROUND_UP_KEY_PRODUCT_FOCUS = 'eastRoundUpKeyProductsFocus',
}

/**
 * A) Seed Trait Match Reward
 * Minimum 100 acres of traits + $1000 on eligible brands
 */
const seedTraitMatchConditions = {
  productQualifyingAmount: 1000,
  traitQualifyingAcres: 100,
};

const seedTraitMatchRule: CalculationRule = {
  id: BayerValueEastRuleId.SEED_TRAIT_MATCH_REWARDS,
  condition: (products, segmentQualification) => {
    const requiredProductsAcres = products
      .filter((product) => segmentQualification.segmentRequirement.includes(product.sku))
      .reduce((acc, product) => acc + product.acres, 0);
    const allIndividualProductsQualify = !products
      .filter((product) => segmentQualification.paymentProduct.includes(product.sku))
      .some(
        (product) =>
          product.acres * product.pricePerAcre < seedTraitMatchConditions.productQualifyingAmount
      );

    return (
      requiredProductsAcres >= seedTraitMatchConditions.traitQualifyingAcres && // Acres for traits should be at least 100
      allIndividualProductsQualify // Payment product should be at least $1000
    );
  },
  calculate: (products, segmentQualification) => {
    const acresToMatch = products
      .filter((product) => segmentQualification.segmentRequirement.includes(product.sku))
      .reduce((acc, product) => acc + product.acres, 0);

    const payingProducts: RewardProduct[] = products
      .filter((product) => segmentQualification.paymentProduct.includes(product.sku))
      .map((product) => ({
        ...product,
        estimatedProductReward:
          (product.eastSeedTraitMatchRates ?? 0) * Math.min(product.acres, acresToMatch),
      }));

    const totalRewards = payingProducts.reduce(
      (acc, product) => acc + (product.estimatedProductReward ?? 0),
      0
    );

    return {
      [BayerValueEastRuleId.SEED_TRAIT_MATCH_REWARDS]: {
        reward: totalRewards,
        products: payingProducts,
      },
    };
  },
};

/**
 * B) Unmatched CP Acres rewards
 * Minimum $5000 on eligible brands
 */
const unmatchedCpConditions = {
  fullQualifyingAmount: 5000,
  productQualifyingAmount: 1000,
  minimumAmountOfProducts: 2,
};

const unmatchedCpRule: CalculationRule = {
  id: BayerValueEastRuleId.UNMATCHED_CP_ACRES_REWARDS,
  condition: (products, segmentQualification) => {
    const qualifyingProducts = products.filter((product) =>
      segmentQualification.qualifierProducts.includes(product.sku)
    );
    const fullQualifyingAmount = qualifyingProducts.reduce(
      (sum, product) => sum + product.pricePerAcre * product.acres,
      0
    );
    const allIndividualProductsQualify = !qualifyingProducts.some(
      (product) =>
        product.acres * product.pricePerAcre < unmatchedCpConditions.productQualifyingAmount
    );

    return (
      fullQualifyingAmount >= unmatchedCpConditions.fullQualifyingAmount && // Minimum total from qualifying products is $5000
      allIndividualProductsQualify && // All qualifying products are at leas $1000
      qualifyingProducts.length >= unmatchedCpConditions.minimumAmountOfProducts // There are at least 2 qualifying products
    );
  },
  calculate: (products, segmentQualification) => {
    const payingProducts: RewardProduct[] = products
      .filter((product) => segmentQualification.paymentProduct.includes(product.sku))
      .map((product) => ({
        ...product,
        estimatedProductReward: product.acres * (product.eastUnmatchedCPRates ?? 0),
      }));
    const totalRewards = payingProducts.reduce(
      (acc, product) => acc + (product.estimatedProductReward ?? 0),
      0
    );
    return {
      [BayerValueEastRuleId.UNMATCHED_CP_ACRES_REWARDS]: {
        reward: totalRewards,
        products: payingProducts,
      },
    };
  },
};

/**
 * C) FieldView rewards
 * Qualifies for segment A or B + FieldView subscription
 */
const fieldViewConditions = {
  subscriptionCost: 500,
};

const fieldViewRule: CalculationRule = {
  id: BayerValueEastRuleId.FIELD_VIEW,
  condition: (products, segmentQualification, qualifyingAcres) =>
    seedTraitMatchRule.condition(products, segmentQualification, qualifyingAcres) ||
    unmatchedCpRule.condition(products, segmentQualification, qualifyingAcres),
  calculate: () => ({
    [BayerValueEastRuleId.FIELD_VIEW]: {
      reward: fieldViewConditions.subscriptionCost / 2,
      products: [],
    },
  }),
};

/**
 * D) Roundup Key Product Focus
 * Minimum $1000 on eligible products + RoundUp
 */
const roundUpKeyProductsConditions = {
  payRate: 0.25,
  productQualifyingAmount: 1000,
  minimumAmountOfProducts: 2,
};

const roundUpKeyProductsRewardsRule: CalculationRule = {
  id: BayerValueEastRuleId.ROUND_UP_KEY_PRODUCT_FOCUS,
  condition: (products, segmentQualification) => {
    const payingProducts = products.filter((product) =>
      segmentQualification.paymentProduct.includes(product.sku)
    );
    const requiredProducts = products.filter((product) =>
      segmentQualification.segmentRequirement.includes(product.sku)
    );

    return (
      !payingProducts.some(
        (product) =>
          product.acres * product.pricePerAcre <
          roundUpKeyProductsConditions.productQualifyingAmount
      ) && // Minimum total from paying products is $1000
      requiredProducts.reduce((acc, product) => acc + product.acres * product.pricePerAcre, 0) >=
        roundUpKeyProductsConditions.productQualifyingAmount && // Minimum total from required products is $1000
      requiredProducts.length >= roundUpKeyProductsConditions.minimumAmountOfProducts // There are at least 2 required products
    );
  },
  calculate: (products, segmentQualification) => {
    const acresToMatch = products
      .filter((product) => segmentQualification.segmentRequirement.includes(product.sku))
      .reduce((acc, product) => product.acres + acc, 0);
    const payingProducts: RewardProduct[] = products
      .filter((product) => segmentQualification.paymentProduct.includes(product.sku))
      .map((product) => ({
        ...product,
        estimatedProductReward:
          product.acres >= acresToMatch
            ? roundUpKeyProductsConditions.payRate * acresToMatch
            : roundUpKeyProductsConditions.payRate * product.acres,
      }));
    const totalRewards = payingProducts.reduce(
      (acc, product) => acc + (product.estimatedProductReward ?? 0),
      0
    );

    return {
      [BayerValueEastRuleId.ROUND_UP_KEY_PRODUCT_FOCUS]: {
        reward: totalRewards,
        products: payingProducts,
      },
    };
  },
};

export const calculationRules: CalculationRule[] = [
  seedTraitMatchRule,
  unmatchedCpRule,
  fieldViewRule,
  roundUpKeyProductsRewardsRule,
];
