import { Tab } from './Tabs.types';

export const getPanelId = (tabId: string) => `tab-panel-${tabId}`;

/**
 * Filters the enabled tabs
 * @param tabs
 * @returns
 */
export const getEnabledTabs = (tabs: Tab[]): Tab[] => tabs.filter((tab) => !tab.disabled);

/**
 * Gets the first enabled tab
 * @param tabs
 * @returns
 */
export const getFirstTab = (tabs: Tab[]): Tab | null => {
  const enabledTabs = getEnabledTabs(tabs);

  if (enabledTabs.length) {
    return enabledTabs[0];
  }

  return null;
};

/**
 * Gets the last enabled tab
 * @param tabs
 * @returns
 */
export const getLastTab = (tabs: Tab[]): Tab | null => {
  const enabledTabs = getEnabledTabs(tabs);

  if (enabledTabs.length) {
    return enabledTabs[enabledTabs.length - 1];
  }

  return null;
};

/**
 * Gets the next enabled tab
 * @param tabs
 * @param currentTabId
 * @returns
 */
export const getNextTab = (tabs: Tab[], currentTabId: string | undefined | null): Tab | null => {
  const enabledTabs = getEnabledTabs(tabs);
  const currentTabIndex = enabledTabs.findIndex((tab) => tab.id === currentTabId);

  if (currentTabIndex !== -1) {
    return enabledTabs[(currentTabIndex + 1) % enabledTabs.length];
  }

  return null;
};

/**
 * Gets the previous enabled tab
 * @param tabs
 * @param currentTabId
 * @returns
 */
export const getPreviousTab = (
  tabs: Tab[],
  currentTabId: string | undefined | null
): Tab | null => {
  const enabledTabs = getEnabledTabs(tabs);
  const currentTabIndex = enabledTabs.findIndex((tab) => tab.id === currentTabId);

  if (currentTabIndex !== -1) {
    if (currentTabIndex === 0) {
      return enabledTabs[enabledTabs.length - 1];
    }

    return enabledTabs[(currentTabIndex - 1) % enabledTabs.length];
  }

  return null;
};

/**
 * Clicks and focuses a tab based on ID
 * @param tabId
 */
export const gotToTab = (tabId: string): void => {
  const tabElement = document.getElementById(tabId);

  if (tabElement) {
    tabElement.click();
    tabElement.focus();
  }
};

/**
 * Clicks and focuses a tab element
 * @param tab
 */
export const clickTab = (tab: Tab | null): void => {
  if (tab) {
    gotToTab(tab.id);
  }
};

/**
 * Gets a tab element based on the page's hash
 * @param tabs
 * @param hash
 * @returns
 */
export const getActiveTabByHash = (tabs: Tab[], hash: string): Tab | null | undefined => {
  if (!hash) {
    return null;
  }

  const formattedHash = hash.substring(1);
  const enabledTabs = getEnabledTabs(tabs);

  const activeElement = document.getElementById(formattedHash);

  if (activeElement) {
    let panel: HTMLElement | null;

    if (
      activeElement.getAttribute('role') === 'tabpanel' &&
      activeElement.getAttribute('aria-labelledby')
    ) {
      panel = activeElement;
    } else {
      panel = activeElement.closest('[role="tabpanel"]');
    }

    if (panel && panel.getAttribute('aria-labelledby')) {
      const tabAnchor = document.getElementById(panel.getAttribute('aria-labelledby') || '');

      if (tabAnchor) {
        return enabledTabs.find((tab) => tab.id === tabAnchor.id);
      }
    }
  }

  return null;
};
