import dayjs, { getRewardEarningPeriodDate } from '../../../../lib/utils/dates';
import { FC, ReactNode, useMemo } from 'react';
import { fullDateEn, fullDateFr } from '../../../../lib/constants/date-formats';

import { CalendarTodayIcon } from '../../../../assets/icons';
import cx from 'classnames';
import styles from './InfoBannerSection.module.scss';
import { useDateFormat } from '../../../../lib/hooks/useDateFormat';
import { useTranslation } from 'react-i18next';

interface Props {
  year: number;
}

const InfoBannerSection: FC<Props> = ({ year }) => {
  const { t } = useTranslation();
  const dateFormat = useDateFormat(fullDateEn, fullDateFr);
  const rewardsPeriod = getRewardEarningPeriodDate(year);
  const isOpen = useMemo(() => dayjs().isBefore(rewardsPeriod.end), [rewardsPeriod.end]);

  const renderBanner = (): ReactNode => (
    <div className={cx(styles['no-rewards'])}>
      <p className={cx(styles['no-rewards__title'])}>
        {t('bayer-value.banner.no-rewards-header', { year })}
      </p>
      <p className={cx(styles['no-rewards__description'])}>
        <CalendarTodayIcon className={cx(styles['no-rewards__icon'])} />
        <span>
          {t('bayer-value.banner.no-rewards-description', {
            date: dayjs(rewardsPeriod.end).add(1, 'day').format(dateFormat),
          })}
        </span>
      </p>
    </div>
  );

  return (
    <div
      className={cx(styles['info-banner-section'], {
        [styles['info-banner-section--no-banner']]: !isOpen,
      })}
    >
      {isOpen && <div className={cx(styles['info-banner-section__wrapper'])}>{renderBanner()}</div>}
    </div>
  );
};

InfoBannerSection.displayName = 'InfoBannerSection';

export default InfoBannerSection;
