import { gql } from '../../__generated__';

export const GET_CALCULATOR_PRODUCTS = gql(`
  query CalculatorProducts($input: CalculatorProductsInput!) {
    calculatorProducts(input: $input) {
      group
      products {
        name
        frenchName
        sku
        pricePerAcre
        mainGroup
        platform
        eastUnmatchedCPRates
        eastSeedTraitMatchRates
      }
    }
  }`);

export const GET_PRODUCT_QUALIFICATIONS = gql(`
  query ProductQualifications($input: CalculatorQualificationsInput!) {
    productQualifications(input: $input) {
      doubleQualifierProducts
      paymentProduct
      qualifierProducts
      segmentRequirement
      segment
    }
  }`);

export const GET_SAVED_CALCULATIONS = gql(`
  query SavedCalculations($input: SavedCalculationsInput!) {
    savedCalculations(input: $input) {
      id
      programYear
      calculation {
        selectedProducts {
          sku
          acres
        }
        totalRewards
      }
      farmId
      name
      lastUpdate
    }
  }`);

export const GET_SAVED_CALCULATIONS_COUNT = gql(`
  query SavedCalculationsCount($input: SavedCalculationsInput!) {
    savedCalculationsCount(input: $input)
  }`);

export const GET_SAVED_CALCULATION_NAMES = gql(`
  query GetSavedCalculationsNames($input: SavedCalculationsInput!) {
    savedCalculations(input: $input) {
      id
      name
    }
  }`);

export const CREATE_CALCULATION = gql(`
  mutation CreateCalculation($input: CreateCalculationInput!) {
    createCalculation(input: $input) {
      id
      name
      lastUpdate
    }
  }`);

export const EDIT_CALCULATION = gql(`
  mutation UpdateCalculation($input: UpdateCalculationInput!) {
    updateCalculation(input: $input) {
      id
      name
      lastUpdate
    }
  }`);

export const DELETE_CALCULATION = gql(`
  mutation DeleteCalculation($input: DeleteCalculationInput!) {
    deleteCalculation(input: $input) {
      id
    }
  }`);

export const DELETE_CALCULATION_LIST = gql(`
  mutation DeleteCalculationList($input: DeleteCalculationListInput!) {
    deleteCalculationList(input: $input)
  }`);

export const GET_PREFILL_PRODUCTS = gql(`
  query PrefillProducts($input: PrefillProductsInput!) {
    prefillProducts(input: $input) {
      missingProducts
      selectedCrops
      selectedProducts {
        acres
        name
        frenchName
        sku
        pricePerAcre
        group
        crop
        eastUnmatchedCPRates
        eastSeedTraitMatchRates
      }
    }
  }`);
