import {
  CalendarTodayIcon,
  DollarOutlineIcon,
  InfoOutlineIcon,
  LockOutlineIcon,
} from '../../../../assets/icons';
import { FC, useEffect, useState } from 'react';
import { getCurrentProgramYear, getLastThreeRewardYears } from '../../../../lib/utils/dates';
import { LinkUrls, PageRoutes } from '../../../../lib/constants/react-router';

import { AccessLevel } from '../../../../__generated__/graphql';
import Breakdown from './Breakdown/Breakdown';
import CardLink from '../../../../components/_shared/CardLink';
import cx from 'classnames';
import { DashboardContextActionTypes } from '../../../../lib/contexts/dashboard/DashboardContext.types';
import { EmptyStateTheme } from '../../../../lib/constants/components';
import { GET_FARM_PROVINCE } from '../../../../lib/graphql/FarmInformation.gql';
import { GET_PROGRAM_REWARDS_BREAKDOWN_QUERY } from '../../../../lib/graphql/ProgramRewards.gql';
import { GET_USER_REWARDS_ACCESS_QUERY } from '../../../../lib/graphql/UserInformation.gql';
import { getRegionSpecificLink } from '../../../../lib/utils/utils';
import Link from '../../../../components/_shared/Link';
import RewardsYearPicker from '../../../../components/_shared/RewardsYearPicker';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from './Skeleton/Skeleton';
import styles from './BayerRewardsWidget.module.scss';
import TableEmptyState from '../../../../components/TableEmptyState/TableEmptyState';
import useDashboardContext from '../../../../lib/contexts/dashboard/useDashboardContext';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const BayerRewardsWidget: FC = () => {
  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState<number>(getLastThreeRewardYears()[0]);
  const [, dispatchDashboard] = useDashboardContext();
  const { data: rewardsAccessData, loading: loadingRewardsData } = useQuery(
    GET_USER_REWARDS_ACCESS_QUERY
  );
  const { data: breakdownData, loading: loadingBreakdown } = useQuery(
    GET_PROGRAM_REWARDS_BREAKDOWN_QUERY,
    {
      variables: { input: { year: selectedYear } },
      skip: !rewardsAccessData || rewardsAccessData.user.hasAccess === AccessLevel.NoAccess,
    }
  );
  const { data: farmProvinceData } = useQuery(GET_FARM_PROVINCE);

  useEffect(() => {
    if (!loadingBreakdown && !breakdownData?.enrolledProgramsInfo) {
      dispatchDashboard({
        type: DashboardContextActionTypes.UpdateDisplayRetreat,
        payload: false,
      });
    }
  }, [breakdownData, loadingBreakdown, dispatchDashboard]);

  const renderView = () => {
    const isPastYear = selectedYear < getCurrentProgramYear();
    if (loadingBreakdown || loadingRewardsData) {
      return <Skeleton />;
    } else if (rewardsAccessData && rewardsAccessData.user.hasAccess === AccessLevel.NoAccess) {
      return (
        <TableEmptyState
          className={cx(styles['bayer-rewards__empty-state'])}
          Icon={LockOutlineIcon}
          title={t('bayer-rewards-widget.no-access.heading')}
          description={t('bayer-rewards-widget.no-access.description')}
          theme={EmptyStateTheme.SECONDARY}
        />
      );
    } else if (breakdownData && !breakdownData.enrolledProgramsInfo) {
      return (
        <TableEmptyState
          className={cx(styles['bayer-rewards__empty-state'])}
          Icon={CalendarTodayIcon}
          title={t('bayer-value.banner.no-rewards-title')}
          description={t(
            isPastYear
              ? 'bayer-value.banner.no-rewards-past-year'
              : 'bayer-value.banner.no-rewards-current-year'
          )}
          theme={EmptyStateTheme.SECONDARY}
        />
      );
    }

    return <Breakdown year={selectedYear} />;
  };

  return (
    <div className={cx(styles['bayer-rewards'])}>
      <CardLink
        as={RouterLink}
        icon={DollarOutlineIcon}
        isHeaderLink
        to={`/${PageRoutes.REWARDS}?year=${selectedYear}`}
        linkText={t('general.view')}
        className={cx(styles['bayer-rewards__card-link'])}
      >
        {t('bayer-rewards-widget.card-title')}
      </CardLink>
      {!loadingRewardsData && rewardsAccessData?.user?.hasAccess !== AccessLevel.NoAccess && (
        <div className={cx(styles['bayer-rewards__years'])}>
          <div className={cx(styles['bayer-rewards__years-inner'])}>
            <RewardsYearPicker
              selectedYear={selectedYear}
              onSelect={(year) => {
                setSelectedYear(year);
              }}
            />
          </div>
        </div>
      )}
      {renderView()}
      <div className={cx(styles['bayer-rewards__footer'])}>
        <InfoOutlineIcon className={cx(styles['bayer-rewards__icon'])} aria-hidden />
        <p className={cx(styles['bayer-rewards__footer-text'])}>
          {`${t('bayer-rewards-widget.footer-text')} `}
          <Link
            href={getRegionSpecificLink(farmProvinceData?.farm?.farmInfo?.address?.province, {
              east: LinkUrls.BAYER_VALUE_CONDITIONS_EAST,
              west: LinkUrls.BAYER_VALUE_CONDITIONS_WEST,
            })}
            target="_blank"
            className={cx(styles['bayer-rewards__footer-link'])}
          >
            {t('general.learn-more')}
          </Link>
        </p>
      </div>
    </div>
  );
};

BayerRewardsWidget.displayName = 'BayerRewardsWidget';

export default BayerRewardsWidget;
