import { CheckmarkIcon, ClockIcon, DollarIcon } from '../../../../assets/icons';
import { Trans, useTranslation } from 'react-i18next';

import { ButtonThemes } from '../../../../lib/constants/components';
import calculatorImg from '../../../../assets/images/bayer-value/calculator.png';
import cx from 'classnames';
import { FC } from 'react';
import LinkButton from '../../../../components/_shared/LinkButton';
import { PageRoutes } from '../../../../lib/constants/react-router';
import { Link as RouterLink } from 'react-router-dom';
import styles from './CalculatorWidget.module.scss';
import { useBreakpoint } from '../../../../lib/hooks';
import useDashboardContext from '../../../../lib/contexts/dashboard/useDashboardContext';

export interface Props {}

const CalculatorWidget: FC<Props> = () => {
  const { t } = useTranslation();
  const { isXs, isMd } = useBreakpoint();
  const [{ displayRetreat }] = useDashboardContext();

  const renderImageWrapper = () => (
    <div className={cx(styles['calculator-widget__image-wrapper'])}>
      <img
        className={cx(styles['calculator-widget__image'])}
        src={calculatorImg}
        alt={t('calculator-widget.image-alt')}
      />
    </div>
  );

  return (
    <div className={cx(styles['calculator-widget'])}>
      <div
        className={cx(styles['calculator-widget__text-wrapper'], {
          [styles['calculator-widget__text-wrapper--no-retreat']]: !displayRetreat,
        })}
      >
        <h2 className={cx(styles['calculator-widget__heading'])}>
          <Trans i18nKey="calculator-widget.heading" components={[<sup />]} />
        </h2>
        <p className={cx(styles['calculator-widget__description'])}>
          {t('calculator-widget.description')}
        </p>
        <ul className={cx(styles['calculator-widget__list'])}>
          <li className={cx(styles['calculator-widget__list-item'])}>
            <span className={cx(styles['calculator-widget__icon-wrapper'])}>
              <CheckmarkIcon className={cx(styles['calculator-widget__icon'])} aria-hidden="true" />
            </span>
            <p className={cx(styles['calculator-widget__icon-text'])}>
              {t('calculator-widget.item-dollar')}
            </p>
          </li>
          <li className={cx(styles['calculator-widget__list-item'])}>
            <span className={cx(styles['calculator-widget__icon-wrapper'])}>
              <ClockIcon className={cx(styles['calculator-widget__icon'])} aria-hidden="true" />
            </span>
            <p className={cx(styles['calculator-widget__icon-text'])}>
              {t('calculator-widget.item-airplane')}
            </p>
          </li>
          <li className={cx(styles['calculator-widget__list-item'])}>
            <span className={cx(styles['calculator-widget__icon-wrapper'])}>
              <DollarIcon className={cx(styles['calculator-widget__icon'])} aria-hidden="true" />
            </span>
            <p className={cx(styles['calculator-widget__icon-text'])}>
              {t('calculator-widget.item-shopping-bag')}
            </p>
          </li>
        </ul>
        {isXs && renderImageWrapper()}
        <LinkButton
          to={PageRoutes.CALCULATOR}
          as={RouterLink}
          className={cx(styles['calculator-widget__button'])}
          theme={ButtonThemes.SECONDARY}
        >
          {t('calculator-widget.cta')}
        </LinkButton>
      </div>
      {!isXs && (isMd || !displayRetreat) && renderImageWrapper()}
    </div>
  );
};

CalculatorWidget.displayName = 'CalculatorWidget';

export default CalculatorWidget;
