export enum FileFormat {
  XLSX = 'xlsx',
  PDF = 'pdf',
}

export enum ExportType {
  PRE_ORDER = 'pre-orders',
  PURCHASE = 'purchases',
  REBATE_OVERVIEW = 'overview',
  REBATE_DETAILS = 'details',
}

export enum MimeType {
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF = 'application/pdf',
}
