import axios, { AxiosResponse } from 'axios';
import { ExportType, FileFormat } from '../constants/exports';
import { OrderDirection, PurchasePreOrderSortField, Region } from '../../__generated__/graphql';

import { buildUrlParams } from '../utils/api';
import { dateTimeUtc } from '../constants/date-formats';
import dayjs from 'dayjs';
import { Endpoints } from '../constants/endpoints';
import { Language } from '../constants/i18n';
import { ProductExport } from '../types/calculator';
import { PurchaseHistoryFilters } from '../models/Purchase.model';
import { v4 as uuid } from 'uuid';

class ExportClient {
  downloadPurchaseHistoryTable(
    query: string | undefined,
    filters: PurchaseHistoryFilters,
    sortBy: PurchasePreOrderSortField,
    sortOrder: OrderDirection,
    lang: string,
    type: ExportType,
    format: FileFormat
  ): Promise<AxiosResponse<any>> {
    const params =
      type === ExportType.PURCHASE
        ? {
            orderDir: sortOrder,
            orderField: sortBy,
            query,
            startDate:
              filters.dateRange?.startDate &&
              dayjs(filters.dateRange?.startDate).format(dateTimeUtc),
            endDate:
              filters.dateRange?.endDate && dayjs(filters.dateRange?.endDate).format(dateTimeUtc),
            productSkus: filters.productSkus,
            programIds: filters.programIds,
            retailerIds: filters.retailerIds,
            farmIds: filters.farmIds,
          }
        : {
            orderDir: sortOrder,
            orderField: sortBy,
            query,
            startDate:
              filters.dateRange?.startDate &&
              dayjs(filters.dateRange.startDate).format(dateTimeUtc),
            endDate:
              filters.dateRange?.endDate && dayjs(filters.dateRange.endDate).format(dateTimeUtc),
            productSkus: filters.productSkus,
            retailerIds: filters.retailerIds,
          };

    return axios.get(
      `${Endpoints.DOWNLOAD_EXPORT_LIST}${buildUrlParams(
        {
          ...params,
          lang,
          type,
          format,
        },
        false
      )}`,
      {
        responseType: 'blob',
        withCredentials: true,
        headers: {
          'x-request-id': uuid(),
        },
      }
    );
  }

  downloadRebateStatementsTable(
    lang: string,
    type: ExportType,
    format: FileFormat,
    year: number
  ): Promise<AxiosResponse<any>> {
    return axios.get(
      `${Endpoints.DOWNLOAD_EXPORT_LIST}${buildUrlParams(
        {
          lang,
          type,
          format,
          year,
        },
        false
      )}`,
      {
        responseType: 'blob',
        withCredentials: true,
        headers: {
          'x-request-id': uuid(),
        },
      }
    );
  }

  downloadCalculation(
    products: ProductExport[],
    fieldViewQualifier: boolean,
    region: Region,
    language: Language
  ): Promise<AxiosResponse<any>> {
    return axios.post(
      `${Endpoints.DOWNLOAD_CALCULATION}${buildUrlParams({ language }, false)}`,
      {
        products,
        fieldViewQualifier,
        region,
      },
      {
        responseType: 'blob',
        withCredentials: true,
        headers: {
          'x-request-id': uuid(),
        },
      }
    );
  }
}

export default new ExportClient();
