import { FC, useId } from 'react';
import { fullDateEn, fullDateFr } from '../../../../lib/constants/date-formats';
import { InfoOutlineIcon, SpinnerIcon, Tag } from '../../../../assets/icons';

import { ButtonThemes } from '../../../../lib/constants/components';
import Checkbox from '../../../../components/_shared/Checkbox';
import cx from 'classnames';
import dayjs from 'dayjs';
import { DeleteCalculationsForm } from '../../../../lib/types/calculator';
import InfoBanner from '../../../../components/_shared/InfoBanner';
import { InfoBannerTheme } from '../../../../components/_shared/InfoBanner/InfoBanner.types';
import Modal from '../../../../components/_shared/Modal';
import { SavedCalculation } from '../../../../__generated__/graphql';
import styles from '../CalculationModals.module.scss';
import { useDateFormat } from '../../../../lib/hooks/useDateFormat';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface Props {
  isOpen: boolean;
  isLoading?: boolean;
  savedCalculations: SavedCalculation[];
  onClose: () => void;
  onSave: (calculationsToDelete: string[]) => void;
}

const MaxCalculationsModal: FC<Props> = ({
  isOpen,
  isLoading = false,
  savedCalculations,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const dateFormat = useDateFormat(fullDateEn, fullDateFr);
  const formId = useId();
  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
  } = useForm<DeleteCalculationsForm>({
    defaultValues: {
      calculations: [],
    },
  });

  const onSubmit = () => {
    const formValues = getValues();
    onSave(formValues.calculations);
  };

  return (
    <Modal
      isVisible={isOpen}
      hide={onClose}
      title={t('bayer-rewards-calculator.results.save-calculation.delete-existing-calculation')}
      primaryCta={{
        label: t('general.delete'),
        action: () => handleSubmit,
        disabled: isLoading,
        buttonIcon: isLoading ? SpinnerIcon : undefined,
        buttonIconClassName: cx({
          [styles['calculator-modals__spinner']]: isLoading,
        }),
        buttonFormId: formId,
        buttonType: 'submit',
      }}
      secondaryCta={{
        buttonTheme: ButtonThemes.SECONDARY,
        disabled: isLoading,
        label: t('general.cancel'),
        action: onClose,
      }}
    >
      <div className={styles['calculator-modals__max-calculations-wrapper']}>
        {!!errors.calculations && (
          <InfoBanner
            title={t('bayer-rewards-calculator.results.save-calculation.no-calculations-selected')}
            icon={InfoOutlineIcon}
            closeButton={false}
            className={styles['calculator-modals__info-banner']}
            theme={InfoBannerTheme.DANGER}
          />
        )}
        <div>
          <p className={styles['calculator-modals__disclaimer']}>
            <InfoOutlineIcon className={styles['calculator-modals__disclaimer-icon']} />
            <span>
              {t('bayer-rewards-calculator.results.save-calculation.max-calculations-disclaimer')}
            </span>
          </p>
          <p className={styles['calculator-modals__text']}>
            {t('bayer-rewards-calculator.results.save-calculation.max-calculations-description')}
          </p>
        </div>
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <ul className={cx(styles['calculator-modals__calculations-list'])}>
            {savedCalculations.map((calculation) => (
              <li key={calculation.id}>
                <Checkbox
                  {...register('calculations', {
                    required: t('form.errors.required'),
                  })}
                  disabled={isLoading}
                  label={
                    <div className={styles['calculator-modals__checkbox-label']}>
                      <h2 className={styles['calculator-modals__checkbox-title']}>
                        {calculation.name}
                      </h2>
                      <h3 className={styles['calculator-modals__checkbox-description']}>
                        {t('bayer-rewards-calculator.saved-calculations.last-updated', {
                          date: dayjs(calculation.lastUpdate).format(dateFormat),
                        })}
                      </h3>
                      <div className={styles['calculator-modals__checkbox-details']}>
                        <p className={styles['calculator-modals__checkbox-description']}>
                          {t('bayer-rewards-calculator.saved-calculations.estimated-reward', {
                            reward: t('format.price', {
                              value: calculation.calculation.totalRewards,
                            }),
                          })}
                        </p>
                        <hr className={styles['calculator-modals__checkbox-divider']} />
                        <p className={styles['calculator-modals__checkbox-description']}>
                          {t('bayer-rewards-calculator.saved-calculations.product-count', {
                            productCount:
                              calculation.calculation.selectedProducts.length.toString(),
                          })}
                        </p>
                      </div>
                    </div>
                  }
                  value={calculation.id}
                  image={Tag}
                  hasError={!!errors.calculations}
                  className={{
                    icon: styles['calculator-modals__checkbox-icon'],
                    iconWrapper: styles['calculator-modals__checkbox-icon-wrapper'],
                  }}
                />
              </li>
            ))}
          </ul>
        </form>
      </div>
    </Modal>
  );
};

MaxCalculationsModal.displayName = 'SaveCalculationModal';

export default MaxCalculationsModal;
