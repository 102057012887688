import { ChartData, ChartDataset } from 'chart.js';
import { ChartEntry } from './ProgramDoughnutChart.types';

const ARC_SPACING = 5;
const BACKGROUND_COLOR = '#FFFFFF';
const EMPTY_COLOR = '#EAEAEA';

export const formatChartData = (data: ChartEntry[]): ChartData<'doughnut'> => {
  const result: ChartData<'doughnut'> = {
    labels: [],
    datasets: [],
  };
  const dataset: ChartDataset<'doughnut', number[]> = {
    data: [],
    borderWidth: ARC_SPACING,
    hoverBorderWidth: ARC_SPACING,
    borderColor: BACKGROUND_COLOR,
    hoverBorderColor: BACKGROUND_COLOR,
  };
  const backgroundColors: string[] = [];

  data
    .sort((x, y) => y.amount - x.amount)
    .forEach((entry) => {
      result.labels?.push(entry.programName);
      dataset.data.push(entry.amount);
      backgroundColors.push(entry.color);
    });

  dataset.backgroundColor = backgroundColors;
  dataset.hoverBackgroundColor = backgroundColors;

  result.datasets.push(dataset);

  return result;
};

export const getEmptyChartData = () => ({
  labels: [],
  datasets: [
    {
      data: [1], // A single value so Chart.js renders it
      backgroundColor: [EMPTY_COLOR],
      hoverBackgroundColor: [EMPTY_COLOR],
      borderWidth: 0, // No border
    },
  ],
});

export const getTotalPrice = (data: ChartEntry[]): number =>
  data.reduce(
    (previousValue: number, currentValue: ChartEntry) => previousValue + currentValue.amount,
    0
  );

export const getArcPercentage = (amount: number, total: number, lang: string) =>
  ((amount * 100) / total).toLocaleString(lang, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
