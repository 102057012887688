import { FC, SVGProps } from 'react';

import ContextMenu from '../_shared/ContextMenu';
import { ContextMenuOption } from '../_shared/ContextMenu/ContextMenu.types';
import styles from './SummaryCard.module.scss';

export interface Props {
  heading: string;
  subheading: string;
  primaryDetail?: string;
  secondaryDetail?: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  contextMenuOptions?: ContextMenuOption[];
  loading?: boolean;
  onClick?: () => void;
}

const SummaryCard: FC<Props> = ({
  heading,
  subheading,
  primaryDetail,
  secondaryDetail,
  icon: Icon,
  contextMenuOptions,
  loading = false,
  onClick,
}) => (
  <div className={styles['summary-card']}>
    <div className={styles['summary-card__content']}>
      <div className={styles['summary-card__icon-wrapper']}>
        <Icon className={styles['summary-card__icon']} />
      </div>
      <div className={styles['summary-card__text-wrapper']}>
        {onClick ? (
          <button
            className={styles['summary-card__title-button']}
            onClick={onClick}
            disabled={loading}
          >
            <h2 className={styles['summary-card__title']}>{heading}</h2>
          </button>
        ) : (
          <h2 className={styles['summary-card__title']}>{heading}</h2>
        )}
        <h3 className={styles['summary-card__description']}>{subheading}</h3>
        <div className={styles['summary-card__details']}>
          {primaryDetail && <p className={styles['summary-card__description']}>{primaryDetail}</p>}

          {secondaryDetail && (
            <>
              <hr className={styles['summary-card__divider']} />
              <p className={styles['summary-card__description']}>{secondaryDetail}</p>
            </>
          )}
        </div>
      </div>
    </div>
    {contextMenuOptions && contextMenuOptions.length > 0 && (
      <div className={styles['summary-card__context-wrapper']}>
        <ContextMenu options={contextMenuOptions} loading={loading} />
      </div>
    )}
  </div>
);

SummaryCard.displayName = 'SummaryCard';

export default SummaryCard;
