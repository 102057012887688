import { BayerIconDark, MyBayerIconDark } from '../../assets/icons';
import { FarmInfo, UserAccountInfo } from '../../__generated__/graphql';
import { Link, NavLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import cx from 'classnames';
import { FC } from 'react';
import { FlagKeys } from '../../lib/constants/launch-darkly';
import LanguageDropdown from './subcomponents/LanguageDropdown/LanguageDropdown';
import LaunchDarklyWrapper from '../_shared/LaunchDarklyWrapper';
import { LDIdentityType } from '../_shared/LaunchDarklyWrapper/LaunchDarklyWrapper.types';
import MyAccount from './subcomponents/MyAccount/MyAccount';
import { PageRoutes } from '../../lib/constants/react-router';
import styles from './Header.module.scss';

export interface Props {
  className?: string;
  user: Partial<UserAccountInfo> | undefined;
  farm: Partial<FarmInfo> | undefined;
  displayLogo: boolean;
}

const HeaderDesktop: FC<Props> = ({ className, user, displayLogo, farm }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(className, styles['h-desktop'])}>
      <a
        href="#main"
        className={cx('sr-only', 'sr-only-focus-visible', styles['header__skip-to-main'])}
      >
        {t('header.skip-to-main')}
      </a>
      <div className={cx(styles['h-desktop__branding'])}>
        <Link to="/" className={cx(styles['h-desktop__logo-wrapper'])}>
          <MyBayerIconDark
            className={cx(styles['h-desktop__my-bayer-logo'])}
            aria-label={t('general.bayer-logo')}
          />
        </Link>
      </div>

      <div className={cx(styles['h-desktop__navigation-wrapper'])}>
        <nav className={cx(styles['h-desktop__navigation'])} aria-label={t('header.nav-alt')}>
          {user?.onboardingComplete && (
            <ul className={cx(styles['h-desktop__navigation-list'])}>
              <li className={cx(styles['h-desktop__navigation-item'])}>
                <NavLink
                  to={PageRoutes.HOME}
                  className={({ isActive }) =>
                    cx(styles['h-desktop__navigation-link'], {
                      [styles['h-desktop__navigation-link--active']]: isActive,
                    })
                  }
                >
                  {t('header.links.dashboard')}
                </NavLink>
              </li>
              <li className={cx(styles['h-desktop__navigation-item'])}>
                <NavLink
                  to={PageRoutes.ORDER_PURCHASE_HISTORY}
                  className={({ isActive }) =>
                    cx(styles['h-desktop__navigation-link'], {
                      [styles['h-desktop__navigation-link--active']]: isActive,
                    })
                  }
                >
                  {t('header.links.order-purchase-history')}
                </NavLink>
              </li>
              <li className={cx(styles['h-desktop__navigation-item'])}>
                <NavLink
                  to={PageRoutes.REWARDS}
                  className={({ isActive }) =>
                    cx(styles['h-desktop__navigation-link'], {
                      [styles['h-desktop__navigation-link--active']]: isActive,
                    })
                  }
                >
                  <Trans i18nKey="header.links.bayer-value-rewards" components={[<sup />]} />
                </NavLink>
              </li>

              <LaunchDarklyWrapper
                flagKey={FlagKeys.CALCULATOR_PAGE}
                identityType={LDIdentityType.ID}
              >
                <li className={cx(styles['h-desktop__navigation-item'])}>
                  <NavLink
                    to={PageRoutes.CALCULATOR}
                    className={({ isActive }) =>
                      cx(styles['h-desktop__navigation-link'], {
                        [styles['h-desktop__navigation-link--active']]: isActive,
                      })
                    }
                  >
                    <Trans i18nKey="header.links.bayer-value-calculator" components={[<sup />]} />
                  </NavLink>
                </li>
              </LaunchDarklyWrapper>
            </ul>
          )}
        </nav>

        <div className={cx(styles['h-desktop__right-container'])}>
          <div className={cx(styles['h-desktop__language-wrapper'])}>
            <LanguageDropdown />
          </div>
          {user && (
            <>
              <div className={cx(styles['h-desktop__separator-wrapper'])}>
                <div className={cx(styles['h-desktop__separator'])}></div>
              </div>
              <div className={cx(styles['h-desktop__account-wrapper'])}>
                <MyAccount user={user} farmProvince={farm?.address?.province} />
              </div>
            </>
          )}
          {displayLogo && <BayerIconDark className={cx(styles['h-desktop__bayer-logo'])} />}
        </div>
      </div>
    </div>
  );
};

HeaderDesktop.displayName = 'HeaderDesktop';

export default HeaderDesktop;
