import { ActionKey, ContextMenuOption, ContextMenuPosition } from './ContextMenu.types';
import { FC, useEffect, useRef, useState } from 'react';
import { MeatBall, SpinnerIcon } from '../../../assets/icons';

import { ActionCta } from '../../../lib/types/button';
import Button from '../Button';
import cx from 'classnames';
import styles from './ContextMenu.module.scss';
import { useTranslation } from 'react-i18next';

export interface ContextMenuProps {
  options: ContextMenuOption[];
  cta?: ActionCta;
  loading?: boolean;
}

const ContextMenu: FC<ContextMenuProps> = ({ options, cta, loading = false }) => {
  const { t } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState<ContextMenuPosition>(ContextMenuPosition.RIGHT);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const menuRef = useRef<HTMLDivElement>(null);
  const ctaRef = useRef<HTMLButtonElement>(null);
  const iconButtonRef = useRef<HTMLButtonElement>(null);
  const buttonRef = cta ? ctaRef : iconButtonRef;
  const buttonId = cta ? `context-menu-cta-${cta.id}` : 'context-menu-icon';

  useEffect(() => {
    if (menuVisible) {
      menuRef.current?.focus();
    }
  }, [menuVisible]);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const windowWidth = window.innerWidth;
    const button = buttonRef.current;
    const buttonPosition = button?.getBoundingClientRect().x ?? 0;

    if (buttonPosition >= windowWidth / 2) {
      setMenuPosition(ContextMenuPosition.LEFT);
    } else {
      setMenuPosition(ContextMenuPosition.RIGHT);
    }

    setMenuVisible(!menuVisible);
  };

  const handleCloseMenu = () => {
    setMenuVisible(false);
    buttonRef.current?.focus();
  };

  const handleOptionClick = (option: ContextMenuOption) => {
    option.action();
    setMenuVisible(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!menuVisible) return;

    switch (e.key) {
      case ActionKey.ARROW_UP:
        setSelectedIndex((prev) => (prev === null || prev === 0 ? options.length - 1 : prev - 1));
        break;
      case ActionKey.ARROW_DOWN:
        setSelectedIndex((prev) => (prev === null || prev === options.length - 1 ? 0 : prev + 1));
        break;
      case ActionKey.ENTER:
        if (selectedIndex !== null) {
          handleOptionClick(options[selectedIndex]);
        }
        break;
      case ActionKey.ESCAPE:
        handleCloseMenu();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: PointerEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        handleCloseMenu();
      }
    };

    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleBlur = (event: FocusEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.relatedTarget as Node) &&
        menuRef.current &&
        !menuRef.current.contains(event.relatedTarget as Node)
      ) {
        handleCloseMenu();
      }
    };

    menuRef.current?.addEventListener('focusout', handleBlur);
    return () => {
      menuRef.current?.removeEventListener('focusout', handleBlur);
    };
  }, [menuVisible]);

  return (
    <div className={styles['context-menu']}>
      {cta ? (
        <Button
          id={buttonId}
          className={cta.buttonClassName}
          ref={ctaRef}
          onClick={handleContextMenu}
          disabled={cta.disabled || loading}
          aria-haspopup="true"
          aria-expanded={menuVisible}
          icon={loading ? SpinnerIcon : cta.buttonIcon}
          iconClassName={cta.buttonIconClassName}
          iconPosition={cta.buttonIconPosition}
          theme={cta.buttonTheme}
        >
          {cta.label}
        </Button>
      ) : (
        <button
          id={buttonId}
          className={styles['context-menu__button']}
          ref={iconButtonRef}
          aria-haspopup="true"
          aria-expanded={menuVisible}
          onClick={handleContextMenu}
          type="button"
          disabled={loading}
        >
          <span className={cx('sr-only')}>{t('general.expand')}</span>
          {loading ? (
            <SpinnerIcon className={styles['context-menu__button-loading']} />
          ) : (
            <MeatBall />
          )}
        </button>
      )}
      {menuVisible && (
        <div
          ref={menuRef}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          className={cx(styles['context-menu__modal'], {
            [styles[`context-menu__modal--icon-${menuPosition}`]]: !cta,
            [styles[`context-menu__modal--cta-${menuPosition}`]]: cta,
          })}
          role="menu"
          aria-live="polite"
          aria-labelledby={buttonId}
          aria-activedescendant={selectedIndex !== null ? `menu-item-${selectedIndex}` : undefined}
        >
          {options.map((option, index) => (
            <button
              id={`menu-item-${option.label}`}
              key={option.label}
              onClick={() => handleOptionClick(option)}
              onMouseEnter={() => setSelectedIndex(index)}
              className={cx(styles['context-menu__item'], {
                [styles['context-menu__item--selected']]: selectedIndex === index,
              })}
              role="menuitem"
              type="button"
              tabIndex={-1}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

ContextMenu.displayName = 'ContextMenu';

export default ContextMenu;
