import { ButtonThemes, IconPosition } from '../../../../lib/constants/components';
import { CalculatorAction, CropType } from '../../../../lib/types/calculator';
import { CalculatorStep, StepComponentProps } from '../../../../lib/constants/stepper';
import { ChevronLeftIcon, Tag } from '../../../../assets/icons';
import dayjs, { getCurrentProgramYear } from '../../../../lib/utils/dates';
import {
  DELETE_CALCULATION,
  EDIT_CALCULATION,
  GET_CALCULATOR_PRODUCTS,
  GET_PRODUCT_QUALIFICATIONS,
  GET_SAVED_CALCULATIONS,
} from '../../../../lib/graphql/Calculator.gql';
import { FC, useEffect, useState } from 'react';
import { fullDateEn, fullDateFr } from '../../../../lib/constants/date-formats';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import Button from '../../../../components/_shared/Button';
import { CalculatorContextActionTypes } from '../../../../lib/contexts/calculator/CalculatorContext.types';
import { ContextMenuOption } from '../../../../components/_shared/ContextMenu/ContextMenu.types';
import cx from 'classnames';
import DeleteCalculationModal from '../../modals/DeleteCalculationModal';
import { matchSavedProductList } from '../../../../lib/utils/calculator';
import SaveCalculationModal from '../../modals/SaveCalculationModal';
import { SavedCalculation } from '../../../../__generated__/graphql';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import Spinner from '../../../../components/_shared/Spinner';
import styles from './SavedCalculations.module.scss';
import SummaryCard from '../../../../components/SummaryCard';
import useCalculatorContext from '../../../../lib/contexts/calculator/useCalculatorContext';
import { useDateFormat } from '../../../../lib/hooks/useDateFormat';
import { usePreferredRegion } from '../../../../lib/hooks/usePreferredRegion';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

const SavedCalculations: FC<StepComponentProps> = ({ goToStep }) => {
  // Utility Hooks & Constants
  const { t } = useTranslation();
  const programYear = getCurrentProgramYear();
  const dateFormat = useDateFormat(fullDateEn, fullDateFr);
  const SHOW_LIMIT = 5;
  const variables = { input: { programYear } };

  // Context Hooks
  const [, dispatchSnackbar] = useSnackbarContext();
  const [{ region }, dispatchCalculator] = useCalculatorContext();
  const { programRegion, isLoading: isRegionLoading } = usePreferredRegion();

  // State Hooks
  const [selectedCalculation, setSelectedCalculation] = useState<SavedCalculation | undefined>();
  const [isShowingAll, setIsShowingAll] = useState<boolean>(false);
  const [savedCalculations, setSavedCalculations] = useState<SavedCalculation[]>([]);
  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  // Queries & Mutations
  const {
    loading: getSavedCalculationsLoading,
    error: getSavedCalculationsError,
    data: savedCalculationsData,
  } = useQuery(GET_SAVED_CALCULATIONS, {
    variables,
  });
  const [getCalculatorProducts, { loading: loadingProducts }] =
    useLazyQuery(GET_CALCULATOR_PRODUCTS);
  const [getCalculatorQualifications, { loading: loadingQualifications }] = useLazyQuery(
    GET_PRODUCT_QUALIFICATIONS
  );
  const [deleteCalculation, { loading: deletingCalculation }] = useMutation(DELETE_CALCULATION, {
    refetchQueries: [{ query: GET_SAVED_CALCULATIONS, variables }],
  });
  const [editCalculation, { loading: editingCalculation }] = useMutation(EDIT_CALCULATION, {
    refetchQueries: [{ query: GET_SAVED_CALCULATIONS, variables }],
  });

  const onCloseRenameModal = () => {
    setSelectedCalculation(undefined);
    setSaveModalOpen(false);
  };

  const onCloseDeleteModal = () => {
    setSelectedCalculation(undefined);
    setDeleteModalOpen(false);
  };

  const handleError = () => {
    dispatchSnackbar({
      type: SnackbarContextActionTypes.AddToQueue,
      payload: { label: t('errors.generic'), state: SnackbarStates.WARNING },
    });
  };

  const handleLoadCalculation = (calculation: SavedCalculation, redirect?: CalculatorStep) => {
    if (!region) return;
    Promise.all([
      getCalculatorQualifications({ variables: { input: { region } } }),
      getCalculatorProducts({
        variables: { input: { region, crops: Object.values(CropType) } },
      }),
    ])
      .then(([qualificationsResponse, productsResponse]) => {
        if (qualificationsResponse?.data) {
          dispatchCalculator({
            type: CalculatorContextActionTypes.UpdateProductQualifications,
            payload: {
              productQualifications: qualificationsResponse.data.productQualifications || [],
            },
          });
        }
        if (productsResponse.data) {
          const fullProductList = matchSavedProductList(
            calculation,
            productsResponse.data.calculatorProducts
          );

          dispatchCalculator({
            type: CalculatorContextActionTypes.LoadSavedCalculation,
            payload: {
              id: calculation.id,
              name: calculation.name,
              date: calculation.lastUpdate,
              products: fullProductList.selectedProducts,
              crops: fullProductList.selectedCrops,
            },
          });

          if (redirect) {
            goToStep?.(redirect);
          }
        }
      })
      .catch(handleError);
  };

  const handleRenameCalculation = (newCalculationName: string) => {
    if (!selectedCalculation) return;

    editCalculation({
      variables: { input: { id: selectedCalculation.id, name: newCalculationName } },
    })
      .then(({ data }) => {
        if (data) {
          dispatchCalculator({
            type: CalculatorContextActionTypes.UpdateCalculation,
            payload: {
              id: data.updateCalculation.id,
              name: data.updateCalculation.name,
              date: data.updateCalculation.lastUpdate,
            },
          });
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('bayer-rewards-calculator.results.save-calculation.success-snack'),
              state: SnackbarStates.SUCCESS,
            },
          });
        }
      })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        onCloseRenameModal();
      });
  };

  const handleDeleteCalculation = () => {
    if (!selectedCalculation) {
      return;
    }

    const newCalculationsLength = (savedCalculationsData?.savedCalculations.length ?? 1) - 1;

    deleteCalculation({
      variables: { input: { id: selectedCalculation.id } },
    })
      .then(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('bayer-rewards-calculator.saved-calculations.calculation-deleted'),
            state: SnackbarStates.SUCCESS,
          },
        });

        setDeleteModalOpen(false);
        setSelectedCalculation(undefined);

        if (!newCalculationsLength) {
          goToStep?.(CalculatorStep.INSTRUCTIONS);
        }
      })
      .catch(handleError);
  };

  const getContextMenuOptions = (calculation: SavedCalculation): ContextMenuOption[] => [
    {
      label: t('general.view'),
      action: () => {
        handleLoadCalculation(calculation, CalculatorStep.RESULTS);
      },
    },
    {
      label: t('general.edit'),
      action: () => {
        handleLoadCalculation(calculation, CalculatorStep.SELECT_PRODUCTS);
      },
    },
    {
      label: t('general.rename'),
      action: () => {
        setSelectedCalculation(calculation);
        setSaveModalOpen(true);
      },
    },
    {
      label: t('general.remove'),
      action: () => {
        setSelectedCalculation(calculation);
        setDeleteModalOpen(true);
      },
    },
  ];

  useEffect(() => {
    if (!isRegionLoading && !region) {
      dispatchCalculator({
        type: CalculatorContextActionTypes.UpdateProgramRegion,
        payload: programRegion,
      });
    }
  }, [isRegionLoading]);

  useEffect(() => {
    if (getSavedCalculationsError) {
      handleError();
    } else if (!getSavedCalculationsLoading) {
      setSavedCalculations(savedCalculationsData?.savedCalculations ?? []);
    }
  }, [savedCalculationsData]);

  return (
    <section className={styles['saved-calculations']} aria-labelledby="saved-calculations-title">
      {saveModalOpen && (
        <SaveCalculationModal
          isLoading={editingCalculation}
          isOpen={saveModalOpen}
          mode={CalculatorAction.EDIT}
          onClose={onCloseRenameModal}
          onEdit={handleRenameCalculation}
          defaultValue={selectedCalculation?.name}
        />
      )}
      {deleteModalOpen && (
        <DeleteCalculationModal
          isOpen={deleteModalOpen}
          onClose={onCloseDeleteModal}
          onDelete={handleDeleteCalculation}
        />
      )}
      {getSavedCalculationsLoading || loadingQualifications || loadingProducts ? (
        <div className={styles['saved-calculations__loading']}>
          <Spinner />
        </div>
      ) : (
        <>
          <nav
            className={styles['saved-calculations__actions']}
            aria-label="Saved Calculations Navigation"
          >
            <Button
              className={styles['saved-calculations__back-button']}
              theme={ButtonThemes.SECONDARY}
              icon={ChevronLeftIcon}
              iconPosition={IconPosition.LEFT}
              onClick={() => goToStep?.(CalculatorStep.INSTRUCTIONS)}
            >
              {t('general.back')}
            </Button>
          </nav>
          <div className={styles['saved-calculations__list-container']}>
            <header className={cx(styles['saved-calculations__header'])}>
              <h1 id="saved-calculations-title" className={cx(styles['saved-calculations__title'])}>
                {t('bayer-rewards-calculator.saved-calculations.heading')}
              </h1>
              <p className={cx(styles['saved-calculations__subtitle'])}>
                {t('bayer-rewards-calculator.saved-calculations.description')}
              </p>
            </header>
            <ul className={cx(styles['saved-calculations__list'])}>
              {savedCalculations
                .slice(0, isShowingAll ? savedCalculations.length : SHOW_LIMIT)
                .map((calculation) => (
                  <li className={cx(styles['saved-calculations__item'])} key={calculation.id}>
                    <SummaryCard
                      contextMenuOptions={getContextMenuOptions(calculation)}
                      heading={calculation.name}
                      subheading={t('bayer-rewards-calculator.saved-calculations.last-updated', {
                        date: dayjs(calculation.lastUpdate).format(dateFormat),
                      })}
                      primaryDetail={t(
                        'bayer-rewards-calculator.saved-calculations.estimated-reward',
                        {
                          reward: t('format.price', {
                            value: calculation.calculation.totalRewards,
                          }),
                        }
                      )}
                      secondaryDetail={t(
                        'bayer-rewards-calculator.saved-calculations.product-count',
                        {
                          productCount: calculation.calculation.selectedProducts.length.toString(),
                        }
                      )}
                      icon={Tag}
                      onClick={() => handleLoadCalculation(calculation, CalculatorStep.RESULTS)}
                      loading={calculation.id === selectedCalculation?.id && deletingCalculation}
                    />
                  </li>
                ))}
            </ul>
            {savedCalculations.length > SHOW_LIMIT && !isShowingAll && (
              <Button
                className={styles['saved-calculations__load-more']}
                theme={ButtonThemes.SECONDARY}
                onClick={() => setIsShowingAll(!isShowingAll)}
              >
                {t('bayer-rewards-calculator.saved-calculations.load-more')}
              </Button>
            )}
          </div>
        </>
      )}
    </section>
  );
};

SavedCalculations.displayName = 'SavedCalculations';

export default SavedCalculations;
