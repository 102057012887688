import { ButtonThemes, DisplayTheme, IconPosition } from '../../../../lib/constants/components';
import { FC, ReactElement, useEffect, useMemo, useRef } from 'react';
import { fullDateEn, fullDateFr } from '../../../../lib/constants/date-formats';
import { LinkUrls, PageRoutes } from '../../../../lib/constants/react-router';
import { Trans, useTranslation } from 'react-i18next';

import { ChevronRightIcon } from '../../../../assets/icons';
import cx from 'classnames';
import { DashboardContextActionTypes } from '../../../../lib/contexts/dashboard/DashboardContext.types';
import dayjs from '../../../../lib/utils/dates';
import { GET_BAYER_VALUE_RETREAT_INFO } from '../../../../lib/graphql/ProgramRewards.gql';
import imageDesktop from '../../../../assets/images/bayer-value/program-reward-retreat-desktop.png';
import imageMobile from '../../../../assets/images/bayer-value/program-reward-retreat-mobile.png';
import { isHotPotatoes } from '../../../../lib/utils/programs';
import Link from '../../../../components/_shared/Link';
import LinkButton from '../../../../components/_shared/LinkButton';
import MediaWithContent from '../../../../components/MediaWithContent';
import RetreatSkeleton from './RetreatSkeleton';
import RewardsProgressBar from '../../../../components/RewardsProgressBar/RewardsProgressBar';
import { Link as RouterLink } from 'react-router-dom';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './RetreatSection.module.scss';
import useDashboardContext from '../../../../lib/contexts/dashboard/useDashboardContext';
import { useDateFormat } from '../../../../lib/hooks/useDateFormat';
import { useQuery } from '@apollo/client';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { v4 as uuid } from 'uuid';

export interface Props {
  year: number;
  theme?: DisplayTheme;
}

const RetreatSection: FC<Props> = ({ year, theme = DisplayTheme.BANNER }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [, dispatchDashboard] = useDashboardContext();
  const [, dispatchSnackbar] = useSnackbarContext();
  const dateFormat = useDateFormat(fullDateEn, fullDateFr);
  const progressBarLabelId = useRef(uuid());

  const {
    data: retreatData,
    loading: loadingRetreat,
    error: retreatError,
  } = useQuery(GET_BAYER_VALUE_RETREAT_INFO, { variables: { input: { year } } });

  // Checks if the farm is enrolled for the BayerValue retreat
  const hasSignedUp = useMemo(
    () => !!retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment,
    [retreatData]
  );

  // Stores the initial cost of the retreat
  const initialCost = useMemo(
    () => retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment?.initialCost || 0,
    [retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment?.initialCost]
  );

  // Stores the rebate amount of the retreat
  const rebateAmount = useMemo(
    () => retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment?.rebateAmount || 0,
    [retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment?.rebateAmount]
  );

  // Stores the remaining budget for the retreat
  const budgetRemaining = useMemo(
    () => retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment?.budgetRemaining,
    [retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment?.budgetRemaining]
  );

  // Checks if the rebate information is valid
  const validRebateInformation = useMemo(
    () => rebateAmount > 0 && initialCost > 0,
    [initialCost, rebateAmount]
  );

  // Checks whether the retreat promotion is completed
  const isComplete = useMemo(
    () => validRebateInformation && budgetRemaining === 0,
    [budgetRemaining, validRebateInformation]
  );

  // Calculates the end date of the program
  const rewardsEnd = useMemo<dayjs.Dayjs>(() => {
    const startDate = dayjs(
      retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment?.program?.effectiveFrom
    );
    const duration =
      retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment?.program?.multiYear ||
      1;
    return startDate.add(duration, 'year').subtract(1, 'day');
  }, [retreatData]);

  // Checks if the program is still open
  const isOpen = useMemo<boolean>(() => dayjs().isBefore(rewardsEnd), [rewardsEnd]);

  // Checks if it should render the Retreat Section
  const shouldRender = useMemo<boolean>(
    () =>
      isOpen &&
      hasSignedUp &&
      validRebateInformation &&
      isHotPotatoes(
        retreatData?.bayerValueRetreatEnrollment.bayerValueRetreatEnrollment?.program?.name || ''
      ),
    [
      hasSignedUp,
      isOpen,
      loadingRetreat,
      retreatData?.bayerValueRetreatEnrollment.bayerValueRetreatEnrollment?.program?.name,
      validRebateInformation,
    ]
  );

  useEffect(() => {
    if (retreatError) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [dispatchSnackbar, retreatError, t]);

  useEffect(() => {
    dispatchDashboard({
      type: DashboardContextActionTypes.UpdateDisplayRetreat,
      payload: shouldRender,
    });
  }, [dispatchDashboard, shouldRender]);

  const renderBanner = (): ReactElement => (
    <section
      className={cx(styles['retreat-section'], {
        [styles['retreat-section__skeleton']]: loadingRetreat,
      })}
    >
      {loadingRetreat ? (
        <RetreatSkeleton isEnrolled={hasSignedUp} theme={theme} />
      ) : (
        <MediaWithContent
          imageDesktop={imageDesktop}
          imageMobile={imageMobile}
          imageAlt={t('images.retreat')}
        >
          <div className={cx(styles['retreat-section__content-wrapper'])}>
            <div className={cx(styles['retreat-section__text-wrapper'])}>
              <h2 className={cx(styles['retreat-section__heading'])}>
                {isComplete ? (
                  <Trans
                    i18nKey="program.retreat.success-description"
                    values={{
                      programName:
                        retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment
                          ?.program.name,
                    }}
                    components={{
                      strong: <strong className={cx(styles['retreat-section__heading--bold'])} />,
                      sup: <sup />,
                    }}
                  />
                ) : (
                  <Trans
                    i18nKey="program.retreat.description"
                    values={{
                      value: budgetRemaining,
                      programName:
                        retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment
                          ?.program.name,
                    }}
                    components={[
                      <strong className={cx(styles['retreat-section__heading--bold'])} />,
                    ]}
                  />
                )}
              </h2>
              <p className={cx(styles['retreat-section__subtitle'])}>
                <Trans
                  i18nKey="program.retreat.price"
                  components={[
                    <strong
                      className={cx(
                        styles['retreat-section__subtitle--bold'],
                        styles['retreat-section__subtitle--active']
                      )}
                    />,
                  ]}
                />
              </p>
              <p className={cx(styles['retreat-section__subtitle'])}>
                <Trans
                  i18nKey="program.retreat.subtitle"
                  values={{
                    date: rewardsEnd.locale(language).format(dateFormat),
                  }}
                  components={[
                    <strong
                      className={cx(
                        styles['retreat-section__subtitle--bold'],
                        styles['retreat-section__subtitle--active']
                      )}
                    />,
                  ]}
                />
              </p>
              <hr className={cx(styles['retreat-section__divider'])} role="presentation" />
              <p
                id={progressBarLabelId.current}
                className={cx(styles['retreat-section__subtitle'])}
              >
                <Trans
                  i18nKey="program.retreat.status"
                  values={{
                    currentValue: rebateAmount,
                  }}
                  components={[
                    <strong className={cx(styles['retreat-section__subtitle--bold'])} />,
                  ]}
                />
              </p>
              <RewardsProgressBar
                ariaDescribedById={progressBarLabelId.current}
                currentValue={rebateAmount}
                totalValue={initialCost}
              />
            </div>
            <div className={cx(styles['retreat-section__contact'])}>
              <p className={cx(styles['retreat-section__subtitle'])}>
                {isComplete ? (
                  <Trans
                    i18nKey="program.retreat.success-contact"
                    components={{
                      strong: <strong className={cx(styles['retreat-section__subtitle--bold'])} />,
                      url: <Link href={LinkUrls.GROWER_PROGRAMS} target="_blank" />,
                      phone: <Link href={LinkUrls.PHONE_REBATE_FULFILLMENT_TEL} />,
                    }}
                  />
                ) : (
                  <Trans
                    i18nKey="program.retreat.contact"
                    components={[<Link href={LinkUrls.PHONE_REBATE_FULFILLMENT_TEL} />]}
                  />
                )}
              </p>
            </div>
          </div>
        </MediaWithContent>
      )}
    </section>
  );

  const renderWidget = (): ReactElement =>
    loadingRetreat ? (
      <RetreatSkeleton isEnrolled={hasSignedUp} theme={theme} />
    ) : (
      <div className={cx(styles['retreat-section-widget'])}>
        <div className={cx(styles['retreat-section-widget__inner'])}>
          <div className={cx(styles['retreat-section-widget__heading'])}>
            <p className={cx(styles['retreat-section-widget__title'])}>
              {t('bayer-rewards-widget.retreat-progress')}
            </p>
            <p className={cx(styles['retreat-section-widget__subtitle'])}>
              {isComplete ? (
                <Trans
                  i18nKey="bayer-rewards-widget.retreat.success-description"
                  values={{
                    programName:
                      retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment?.program
                        .name,
                  }}
                  components={{ strong: <strong />, sup: <sup /> }}
                />
              ) : (
                <Trans
                  i18nKey="bayer-rewards-widget.retreat.description"
                  values={{
                    value: budgetRemaining,
                    programName:
                      retreatData?.bayerValueRetreatEnrollment?.bayerValueRetreatEnrollment?.program
                        .name,
                  }}
                  components={[<strong />]}
                />
              )}
            </p>
          </div>
          <div className={cx(styles['retreat-section-widget__body'])}>
            <div className={cx(styles['retreat-section-widget__progress-bar-wrapper'])}>
              <RewardsProgressBar
                ariaDescribedById={progressBarLabelId.current}
                currentValue={rebateAmount}
                totalValue={initialCost}
              />
              <p className={cx(styles['retreat-section-widget__progress-bar-subtitle'])}>
                <Trans
                  i18nKey="bayer-rewards-widget.retreat.status"
                  values={{
                    currentValue: rebateAmount,
                  }}
                  components={[<strong />]}
                />
              </p>
            </div>
          </div>
        </div>
        <LinkButton
          to={`/${PageRoutes.REWARDS}?year=${year}`}
          as={RouterLink}
          theme={ButtonThemes.TEXT_LINK}
          icon={ChevronRightIcon}
          iconPosition={IconPosition.RIGHT}
          className={cx(styles['retreat-section-widget__button'])}
        >
          <Trans i18nKey="bayer-rewards-widget.view-bayer-value" components={[<sup />]} />
        </LinkButton>
      </div>
    );

  const renderView = (): ReactElement => {
    if (theme === DisplayTheme.WIDGET) {
      return renderWidget();
    }

    return renderBanner();
  };

  return shouldRender ? renderView() : null;
};

RetreatSection.displayName = 'RetreatSection';

export default RetreatSection;
