import { ButtonThemes, IconPosition } from '../../../lib/constants/components';
import {   FC, forwardRef, ReactNode, RefObject, SVGProps } from 'react';
import cx from 'classnames';
import { getButtonAriaLabel } from '../../../lib/utils/a11y';
import { PolymorphicComponent } from '../../../lib/models/GenericComponent.model';
import styles from './Button.module.scss';

export interface Props {
  altText?: string;
  children?: ReactNode;
  className?: string;
  icon?: FC<SVGProps<SVGSVGElement>> | null;
  iconClassName?: string;
  iconPosition?: IconPosition;
  onClick?(e?: any): void;
  theme?: ButtonThemes;
  formID?: string;
  ref?: RefObject<HTMLButtonElement>;
}

const Button = forwardRef<HTMLButtonElement, PolymorphicComponent<'button', Props>>(
  (
    {
      altText,
      children,
      className,
      icon: Icon = null,
      iconClassName,
      iconPosition = IconPosition.RIGHT,
      onClick,
      theme = ButtonThemes.PRIMARY,
      type,
      formID,
      ...props
    },
    ref
  ) => (
    <button
      ref={ref} 
      aria-label={getButtonAriaLabel(children, altText)}
      className={cx(className, styles['button'], styles[`button--${theme}`], {
        [styles[`button--i-${iconPosition}`]]: !!Icon,
      })}
      onClick={onClick}
      type={type}
      form={formID}
      {...props}
    >
      {children}
      {Icon && (
        <Icon
          className={cx(
            iconClassName,
            styles['button__icon'],
            styles[`button__icon--${iconPosition}`]
          )}
          aria-hidden="true"
        />
      )}
    </button>
  )
);

Button.displayName = 'Button';

export default Button;
