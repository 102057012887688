import { CalculatorStep, StepComponentProps } from '../../../../lib/constants/stepper';

import Button from '../../../../components/_shared/Button';
import { ButtonThemes } from '../../../../lib/constants/components';
import { FC } from 'react';
import { GET_SAVED_CALCULATIONS_COUNT } from '../../../../lib/graphql/Calculator.gql';
import { getCurrentProgramYear } from '../../../../lib/utils/dates';
import { SpinnerIcon } from '../../../../assets/icons';
import splashScreenVideo from '../../../../assets/videos/bayer-value-calculator/splash-screen.mp4';
import styles from './SplashScreen.module.scss';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const SplashScreen: FC<StepComponentProps> = ({ goToStep, next }: StepComponentProps) => {
  const { t } = useTranslation();
  const programYear = getCurrentProgramYear();
  const { loading: getCalculationsCountLoading, data: calculationsCountData } = useQuery(
    GET_SAVED_CALCULATIONS_COUNT,
    {
      variables: { input: { programYear } },
      fetchPolicy: 'network-only',
    }
  );

  return (
    <div className={styles['splash-screen']}>
      <video src={splashScreenVideo} className={styles['splash-screen__video']} muted autoPlay />
      <div className={styles['splash-screen__content-wrapper']}>
        <h1 className={styles['splash-screen__title']}>
          {t('bayer-rewards-calculator.splash-screen.heading')}
        </h1>
        <p className={styles['splash-screen__subtitle']}>
          {t('bayer-rewards-calculator.splash-screen.subtitle')}
        </p>
        {getCalculationsCountLoading ? (
          <SpinnerIcon className={styles['splash-screen__loader']} />
        ) : (
          <>
            <Button onClick={next} className={styles['splash-screen__button']}>
              {t('general.get-started')}
            </Button>
            {calculationsCountData?.savedCalculationsCount !== undefined &&
              calculationsCountData.savedCalculationsCount > 0 && (
                <Button
                  onClick={() => {
                    goToStep?.(CalculatorStep.SAVED_CALCULATIONS);
                  }}
                  theme={ButtonThemes.SECONDARY}
                  className={styles['splash-screen__button']}
                >
                  {t('bayer-rewards-calculator.splash-screen.view-calculations')}
                </Button>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default SplashScreen;
