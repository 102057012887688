import dayjs, { getRewardEarningPeriodDate } from '../../../../lib/utils/dates';
import { FC, useMemo } from 'react';
import {
  ordinalDayFullMonthYearEn,
  ordinalDayFullMonthYearFr,
} from '../../../../lib/constants/date-formats';
import Pill, { PillColor, PillSizes } from '../../../_shared/Pill';
import { Trans, useTranslation } from 'react-i18next';

import { CalendarTodayIcon } from '../../../../assets/icons';
import cx from 'classnames';
import { GET_TOTAL_REWARDS_EARNED_QUERY } from '../../../../lib/graphql/ProgramRewards.gql';
import styles from './RewardsSection.module.scss';
import { useDateFormat } from '../../../../lib/hooks/useDateFormat';
import { useQuery } from '@apollo/client';

interface Props {
  year: number;
}

const RewardsSection: FC<Props> = ({ year }) => {
  const { t } = useTranslation();
  const rewardsPeriod = getRewardEarningPeriodDate(year);
  const dateFormat = useDateFormat(ordinalDayFullMonthYearEn, ordinalDayFullMonthYearFr);
  const { data: rewardsTotalData } = useQuery(GET_TOTAL_REWARDS_EARNED_QUERY, {
    variables: { input: { year } },
  });
  const isOpen = useMemo(() => dayjs().isBefore(rewardsPeriod.end), [rewardsPeriod.end]);

  return (
    <div className={cx(styles['rewards-section'])}>
      <p className={cx(styles['rewards-section__no-rewards-title'])}>
        <span
          className={cx(
            styles['rewards-section__text'],
            styles['rewards-section__text--disclaimer']
          )}
        >
          {t('punctuation.colon', {
            value: t('bayer-value.program-status'),
          })}
        </span>{' '}
        <Pill
          as="span"
          text={isOpen ? t('general.open') : t('general.closed')}
          size={PillSizes.SM}
          color={isOpen ? PillColor.GREEN : PillColor.RED}
        />
      </p>
      <p className={cx(styles['rewards-section__text'], styles['rewards-section__text--overline'])}>
        {t('punctuation.colon', {
          value: t('bayer-value.total-rewards-earned-date', {
            date: dayjs(rewardsPeriod.start).format(dateFormat),
          }),
        })}
      </p>
      <p className={cx(styles['rewards-section__text'], styles['rewards-section__text--heading'])}>
        {t('format.price', {
          value: rewardsTotalData?.enrolledProgramsInfo?.totalRewardAmountInDollars,
        })}
      </p>
      <div
        className={cx(
          styles['rewards-section__text'],
          styles['rewards-section__text--subtitle'],
          styles['rewards-section__text-with-icon']
        )}
      >
        <CalendarTodayIcon
          className={cx(styles['rewards-section__today-icon'])}
          aria-hidden="true"
        />
        <p className={cx(styles['rewards-section__span-wrapper'])}>
          <span>
            <Trans
              i18nKey="bayer-value.earned-rewards-last-date"
              values={{
                date: dayjs(rewardsPeriod.end).format(dateFormat),
              }}
              components={[
                <span
                  className={cx(
                    styles['rewards-section__text'],
                    styles['rewards-section__text--subtitle-bold'],
                    styles['rewards-section__span']
                  )}
                />,
              ]}
            />
          </span>
        </p>
      </div>
    </div>
  );
};

export default RewardsSection;
