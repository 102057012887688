import { FC, useState } from 'react';
import { getFirstStep, getStepById } from '../../lib/utils/stepper';

import { CALCULATOR_STEPS } from '../../lib/constants/bayer-rewards';
import { CalculatorProvider } from '../../lib/contexts/calculator/CalculatorContext';
import { FlagKeys } from '../../lib/constants/launch-darkly';
import LaunchDarklyWrapper from '../../components/_shared/LaunchDarklyWrapper';
import { LDIdentityType } from '../../components/_shared/LaunchDarklyWrapper/LaunchDarklyWrapper.types';
import { PageRoutes } from '../../lib/constants/react-router';
import { Step } from '../../lib/constants/stepper';
import styles from './BayerRewardsCalculator.module.scss';
import { usePageTitle } from '../../lib/hooks/usePageTitle';

const BayerRewardsCalculator: FC = () => {
  usePageTitle('bayer-value-calculator');
  const [currentStep, setCurrentStep] = useState<Step | null>(getFirstStep(CALCULATOR_STEPS));

  const onNext = () => setCurrentStep(CALCULATOR_STEPS[currentStep?.index ?? 1]);
  const onBack = () => setCurrentStep(CALCULATOR_STEPS[(currentStep?.index ?? 1) - 2]);

  return (
    <LaunchDarklyWrapper
      flagKey={FlagKeys.CALCULATOR_PAGE}
      identityType={LDIdentityType.ID}
      redirectToPage={PageRoutes.REWARDS}
    >
      <CalculatorProvider>
        <div className={styles['calculator']}>
          <div className={styles['calculator__step']}>
            {currentStep?.Component && (
              <currentStep.Component
                next={onNext}
                back={onBack}
                goToStep={(stepId: string) => {
                  setCurrentStep(getStepById(CALCULATOR_STEPS, stepId));
                }}
              />
            )}
          </div>
        </div>
      </CalculatorProvider>
    </LaunchDarklyWrapper>
  );
};

export default BayerRewardsCalculator;
