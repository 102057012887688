import { ButtonThemes } from '../../../../lib/constants/components';
import cx from 'classnames';
import { FC } from 'react';
import Modal from '../../../../components/_shared/Modal';
import styles from '../CalculationModals.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteCalculationModal: FC<Props> = ({ isOpen, onClose, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('bayer-rewards-calculator.delete-modal.title')}
      primaryCta={{ label: t('general.delete'), action: onDelete }}
      secondaryCta={{
        label: t('general.cancel'),
        buttonTheme: ButtonThemes.SECONDARY,
        action: onClose,
      }}
      isVisible={isOpen}
      hide={onClose}
    >
      <p className={cx(styles['calculator-modals__text'], styles['calculator-modals__text--bold'])}>
        {t('bayer-rewards-calculator.delete-modal.description')}
      </p>
      <p
        className={cx(
          styles['calculator-modals__text'],
          styles['calculator-modals__text--low-emphasis']
        )}
      >
        {t('bayer-rewards-calculator.delete-modal.confirmation')}
      </p>
    </Modal>
  );
};

DeleteCalculationModal.displayName = 'DeleteCalculationModal';

export default DeleteCalculationModal;
