import {
  BayerValueEastRuleId,
  calculationRules as calculationRulesEast,
} from '../../pages/BayerRewardsCalculator/rules/east';
import {
  BayerValueWestRuleId,
  calculationRules as calculationRulesWest,
} from '../../pages/BayerRewardsCalculator/rules/west';
import { calculatorGroupOrder, expandedCropList } from '../constants/calculator';
import { CalculatorProducts, Region, SavedCalculation } from '../../__generated__/graphql';
import {
  ProductExport,
  ProductGroup,
  ProductMainGroup,
  QualifyingAcres,
  RewardsBreakdown,
  SelectedProduct,
} from '../types/calculator';

import { CalculatorContextState } from '../contexts/calculator/CalculatorContext.types';
import { Language } from '../constants/i18n';
import { TFunction } from 'i18next';
import { toNormalizeTranslationKey } from './utils';

/**
 * Transforms a list of calculator products into an ordered list of product groups.
 *
 * @param {CalculatorProducts[]} calculatorProducts - The array of product groups with their products.
 * @param {TFunction} t - Translation function.
 * @returns {ProductGroup[]} - The transformed and sorted list of product groups.
 */
export const transformProductsByGroup = (
  calculatorProducts: CalculatorProducts[],
  language: Language,
  t: TFunction
): ProductGroup[] => {
  const groupOrderMap = new Map(
    calculatorGroupOrder.map(({ groupKey, index }) => [groupKey, index])
  );

  return calculatorProducts
    .map((group) => ({
      key: group.group,
      groupName: t(`product.main-group.${toNormalizeTranslationKey(group.group)}`),
      options: group.products.map((product) => ({
        label: language === Language.En ? product.name : product.frenchName,
        value: product.sku,
        product,
      })),
    }))
    .sort((a, b) => {
      const indexA = groupOrderMap.get(a.key as ProductMainGroup) ?? 10;
      const indexB = groupOrderMap.get(b.key as ProductMainGroup) ?? 10;

      return indexA - indexB;
    });
};

/**
 * Calculates the total qualifying acres for different product categories based on selected products.
 *
 * @param {SelectedProduct[]} products - List of selected products with acreage.
 * @param {string[]} qualifierProducts - Products that qualify for standard multipliers.
 * @param {string[]} doubleQualifierProducts - Products that qualify for double multipliers.
 * @returns {QualifyingAcres} - The computed qualifying acres categorized by product type.
 */
export const calculateQualifyingAcres = (
  products: SelectedProduct[],
  qualifierProducts: string[],
  doubleQualifierProducts: string[]
): QualifyingAcres => {
  const { herbicides, fungicides, seedTreatment, insecticides, trait } = products.reduce(
    (totals, product) => {
      let multiplier = 0;

      if (doubleQualifierProducts.includes(product.sku)) {
        multiplier = 2;
      } else if (qualifierProducts.includes(product.sku)) {
        multiplier = 1;
      }

      if (multiplier > 0) {
        return {
          ...totals,
          herbicides:
            product.group === ProductMainGroup.HERBICIDES
              ? totals.herbicides + product.acres * multiplier
              : totals.herbicides,
          fungicides:
            product.group === ProductMainGroup.FUNGICIDES
              ? totals.fungicides + product.acres * multiplier
              : totals.fungicides,
          seedTreatment:
            product.group === ProductMainGroup.SEED_TREATMENT
              ? totals.seedTreatment + product.acres * multiplier
              : totals.seedTreatment,
          insecticides:
            product.group === ProductMainGroup.INSECTICIDE
              ? totals.insecticides + product.acres * multiplier
              : totals.insecticides,
          trait:
            product.group === ProductMainGroup.TRAIT
              ? totals.insecticides + product.acres * multiplier
              : totals.insecticides,
        };
      }

      return totals;
    },
    { herbicides: 0, fungicides: 0, seedTreatment: 0, insecticides: 0, trait: 0 }
  );

  return { herbicides, fungicides, seedTreatment, insecticides, trait };
};

/**
 * Updates the total cost, rewards, and rewards breakdown for the calculator state.
 *
 * @param {CalculatorContextState} state - The current state of the calculator.
 * @returns {CalculatorContextState} - The updated state with calculated cost and rewards.
 *
 * The function:
 * - Determines calculation rules based on the selected region.
 * - Computes the running total cost based on selected products.
 * - Iterates through reward rules, checking if products qualify.
 * - Calculates and aggregates rewards for each qualifying segment.
 * - Updates the state with the total cost, total rewards, and a detailed rewards breakdown.
 */
export const updateRewardsAndCost = (state: CalculatorContextState): CalculatorContextState => {
  const calculationRules =
    state.region === Region.East ? calculationRulesEast : calculationRulesWest;
  const runningTotal = state.selectedProducts.reduce(
    (sum, product) => sum + product.acres * product.pricePerAcre,
    0
  );

  const rewardsBreakdown: RewardsBreakdown = {};
  const estimatedRewards = calculationRules.reduce((rewardSum, rule) => {
    const segmentQualification = state.productQualifications.find(
      (qualification) => qualification.segment === rule.id
    );

    const qualifyingAcres = calculateQualifyingAcres(
      state.selectedProducts,
      segmentQualification?.qualifierProducts || [],
      segmentQualification?.doubleQualifierProducts || []
    );

    if (
      segmentQualification &&
      rule.condition(state.selectedProducts, segmentQualification, qualifyingAcres)
    ) {
      const ruleBreakdown = rule.calculate(
        state.selectedProducts,
        segmentQualification,
        qualifyingAcres
      );

      Object.entries(ruleBreakdown).forEach(([type, details]) => {
        if (!rewardsBreakdown[type]) {
          rewardsBreakdown[type] = {
            reward: 0,
            products: [],
          };
        }

        rewardsBreakdown[type].reward += details.reward;
        rewardsBreakdown[type].products.push(...details.products);
        rewardsBreakdown[type].programUrl = details.programUrl;
      });

      return rewardSum + Object.values(ruleBreakdown).reduce((sum, item) => sum + item.reward, 0);
    }
    return rewardSum;
  }, 0);

  return {
    ...state,
    totalCost: runningTotal,
    totalRewards: estimatedRewards,
    breakdown: rewardsBreakdown,
  };
};

/**
 * Generates a unique calculation name based on the provided date and existing names.
 *
 * @param {string[]} existingNames - List of existing calculation names.
 * @param {string} date - The date to include in the calculation name.
 * @param {TFunction} t - Translation function for localization.
 * @returns {string} - The generated unique calculation name.
 */
export const generateCalculationName = (existingNames: string[], date: string, t: TFunction) => {
  const baseName = t('bayer-rewards-calculator.results.calculation.default-name', { date });

  const duplicateCount = existingNames.filter((name) => name.includes(baseName)).length;

  if (duplicateCount > 0) {
    return `${baseName} (${duplicateCount})`;
  }

  return baseName;
};

/**
 * Matches saved calculation products with the current product list.
 *
 * @param {SavedCalculation} savedCalculation - The saved calculation containing selected products.
 * @param {CalculatorProducts[]} productList - The list of available products grouped by category.
 * @returns {{ selectedProducts: SelectedProduct[], selectedCrops: string[] }} - The matched products and unique selected crops.
 */
export const matchSavedProductList = (
  savedCalculation: SavedCalculation,
  productList: CalculatorProducts[]
) => {
  const ungroupedProductList = productList.flatMap((group) => group.products);
  const { selectedProducts: savedProducts } = savedCalculation.calculation;

  const selectedProducts: SelectedProduct[] = [];
  const selectedCrops = new Set<string>();

  savedProducts.forEach((savedProduct) => {
    const { sku, acres } = savedProduct;
    const fullProduct = ungroupedProductList.find((product) => product.sku === sku);

    if (fullProduct) {
      selectedProducts.push({
        sku,
        acres,
        group: fullProduct.mainGroup,
        crop: fullProduct.platform,
        pricePerAcre: fullProduct.pricePerAcre,
        name: fullProduct.name,
        frenchName: fullProduct.frenchName,
        eastUnmatchedCPRates: fullProduct?.eastUnmatchedCPRates ?? undefined,
        eastSeedTraitMatchRates: fullProduct?.eastSeedTraitMatchRates ?? undefined,
      });
      selectedCrops.add(fullProduct.platform);
    }
  });

  return { selectedProducts, selectedCrops: Array.from(selectedCrops) };
};

/**
 * Formats the calculation breakdown and selected products into
 * an object for file download.
 *
 * @param breakdown
 * @param selectedProducts
 * @param language
 * @param t
 * @returns
 */
export const calculationToDownloadFormat = (
  breakdown: RewardsBreakdown,
  selectedProducts: SelectedProduct[],
  language: Language,
  t: TFunction
): { products: ProductExport[]; fieldViewQualifier: boolean } => {
  const products: ProductExport[] = [];

  selectedProducts.forEach((product) => {
    products.push({
      sku: product.sku,
      category: t(`product.main-group.${toNormalizeTranslationKey(product.group)}`),
      product: language === Language.En ? product.name : product.frenchName,
      crop: expandedCropList(t).find((crop) => crop.value === product.crop)?.label ?? '',
      acres: product.acres,
      rewardsPerProduct: 0,
      rewardsPerAcre: 0,
    });
  });

  let fieldViewQualifier = false;

  Object.entries(breakdown).forEach(([rewardKey, details]) => {
    if (
      rewardKey === BayerValueEastRuleId.FIELD_VIEW ||
      rewardKey === BayerValueWestRuleId.FIELD_VIEW
    ) {
      fieldViewQualifier = true;
    }

    details.products.forEach((product) => {
      const selectedProduct = products.find((element) => element.sku === product.sku);

      if (selectedProduct && product.estimatedProductReward && product.estimatedProductReward > 0) {
        selectedProduct[rewardKey] = product.estimatedProductReward;
        selectedProduct.rewardsPerProduct += product.estimatedProductReward;
        selectedProduct.rewardsPerAcre += product.estimatedProductReward / selectedProduct.acres;
      }
    });
  });

  return { products, fieldViewQualifier };
};
