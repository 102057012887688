import { CalculatorProduct, ProductQualification } from '../../__generated__/graphql';
import { Color } from '../constants/charts';

export enum CalculatorAction {
  EDIT = 'edit',
  SAVE = 'save',
  SAVE_AS_NEW = 'saveNew',
  SAVE_BEFORE_LEAVE = 'saveBeforeLeave',
}

export enum ProductMainGroup {
  FUNGICIDES = 'Fungicides',
  HERBICIDES = 'Herbicides',
  INSECTICIDE = 'Insecticides',
  SEED_TREATMENT = 'Seed Treatment',
  TRAIT = 'Trait',
}

export enum CropType {
  CANOLA = 'Canola',
  CEREALS = 'Cereals',
  CORN = 'Corn',
  FRUIT_AND_VEGETABLE = 'Fruit & Vegetable',
  OILSEEDS = 'Oilseeds',
  PULSES = 'Pulses',
  SOYBEANS = 'Soybeans',
}

export const ResultsChartColor = [
  Color.PINK,
  Color.GREEN,
  Color.DARK_BLUE,
  Color.ORANGE,
  Color.PURPLE,
  Color.RED,
  Color.LIGHT_BLUE,
];

export enum PreFillOption {
  YES = 'yes',
  NO = 'no',
}
export interface CalculatorPreferencesForm {
  preFillForm: PreFillOption;
  crops: string[];
}

export interface DeleteCalculationsForm {
  calculations: string[];
}

export interface CalculatorCategory {
  key: ProductMainGroup;
  title: string;
  subtitle: string;
}

export interface ProductGroup {
  key: string;
  groupName: string;
  options: ProductGroupOption[];
}
export interface ProductGroupOption {
  label: string;
  value: string;
  product: CalculatorProduct;
}

export interface SelectedProduct {
  group: string;
  crop: string;
  sku: string;
  acres: number;
  pricePerAcre: number;
  name: string;
  frenchName: string;
  eastUnmatchedCPRates?: number;
  eastSeedTraitMatchRates?: number;
}

export interface RewardProduct extends SelectedProduct {
  estimatedProductReward?: number;
}

export interface RewardsDetails {
  reward: number;
  products: RewardProduct[];
  programUrl?: string;
}

export interface RewardsBreakdown {
  [key: string]: RewardsDetails;
}

export interface CalculationRule {
  id: string; // Unique identifier for the rule
  condition: (
    products: SelectedProduct[],
    segmentQualification: ProductQualification,
    qualifyingAcres: QualifyingAcres
  ) => boolean; // Function to check if the rule applies
  calculate: (
    products: SelectedProduct[],
    segmentQualification: ProductQualification,
    qualifyingAcres: QualifyingAcres
  ) => RewardsBreakdown; // Function to calculate the reward and return it in the RewardsBreakdown format
}

export interface QualifyingAcres {
  herbicides: number;
  insecticides: number;
  fungicides: number;
  seedTreatment: number;
  trait: number;
}

export interface ProductExport {
  sku: string;
  acres: number;
  category: string;
  crop: string;
  product: string;
  rewardsPerProduct: number;
  rewardsPerAcre: number;
  [rewardKey: string]: any;
}
