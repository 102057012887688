import { FC, useEffect } from 'react';
import Pill, { PillColor, PillSizes } from '../../../../components/_shared/Pill';
import { Trans, useTranslation } from 'react-i18next';

import { ChequeStatus } from '../../../../__generated__/graphql';
import cx from 'classnames';
import dayjs from 'dayjs';
import { EMPTY_STATE_COLSPAN_REBATES } from '../../../../lib/constants/cheques';
import { GET_CHEQUES } from '../../../../lib/graphql/Rebates.gql';
import { mapChequeStatus } from '../../../../lib/utils/cheques';
import { PaginationProvider } from '../../../../lib/contexts/pagination/PaginationContext';
import RebateDetails from './components/RebateDetails/RebateDetails';
import RebateOverview from './components/RebateOverview/RebateOverview';
import { ReceiptIcon } from '../../../../assets/icons';
import { slashSeparatedDate } from '../../../../lib/constants/date-formats';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import Spinner from '../../../../components/_shared/Spinner';
import { SpinnerSizes } from '../../../../lib/constants/components';
import styles from './ChequesSection.module.scss';
import TableEmptyState from '../../../../components/TableEmptyState';
import Tabs from '../../../../components/_shared/Tabs';
import { useBreakpoint } from '../../../../lib/hooks';
import { useQuery } from '@apollo/client';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';

interface Props {
  year: number;
}

const ChequesSection: FC<Props> = ({ year }) => {
  const { t } = useTranslation();
  const [, dispatchSnackbar] = useSnackbarContext();
  const { isXs } = useBreakpoint();
  const {
    data: chequeData,
    loading: chequeLoading,
    error: chequeError,
  } = useQuery(GET_CHEQUES, {
    variables: { input: { year } },
  });

  useEffect(() => {
    if (chequeError) {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: {
          label: t('errors.generic'),
          state: SnackbarStates.WARNING,
        },
      });
    }
  }, [chequeError, dispatchSnackbar, t]);

  return (
    <section className={cx(styles['cheques-section'])}>
      <div className={cx(styles['cheques-section__wrapper'])}>
        <div className={cx(styles['cheques-section__header'])}>
          <div>
            <h2 className={cx(styles['cheques-section__title'])}>
              {t('bayer-value.rebates.heading')}
            </h2>
            <p className={cx(styles['cheques-section__sub-title'])}>
              {t('bayer-value.rebates.description')}
            </p>
          </div>
        </div>

        {chequeLoading ? (
          <div className={cx(styles['spinner-wrapper'])}>
            <Spinner size={SpinnerSizes.LG} />
          </div>
        ) : (
          <table className={cx(styles['table'])}>
            <thead className={cx({ 'sr-only': isXs })}>
              <tr>
                <th className={cx(styles['table__header'])}>
                  {t('bayer-value.rebates.table.number')}
                </th>
                <th className={cx(styles['table__header'])}>
                  {t('bayer-value.rebates.table.date')}
                </th>
                <th className={cx(styles['table__header'])}>
                  {t('bayer-value.rebates.table.payee')}
                </th>
                <th className={cx(styles['table__header'], styles[`table__header--right`])}>
                  {t('bayer-value.rebates.table.value')}
                </th>
                <th className={cx(styles['table__header'], styles[`table__header--right`])}>
                  {t('bayer-value.rebates.table.status')}
                </th>
              </tr>
            </thead>
            {!chequeData?.cheques?.cheques.length ? (
              <tbody>
                <tr className={cx(styles['table__empty-state'])}>
                  <td
                    className={cx(styles['table__empty-state-td'])}
                    colSpan={EMPTY_STATE_COLSPAN_REBATES}
                    aria-label={t('bayer-value.rebates.empty-state-heading')}
                  >
                    <TableEmptyState
                      Icon={ReceiptIcon}
                      title={t('bayer-value.rebates.empty-state.my-rebates.heading')}
                      description={t('bayer-value.rebates.empty-state.my-rebates.description')}
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className={cx(styles['table__tbody'])}>
                {chequeData?.cheques?.cheques.map((cheque) => (
                  <tr key={cheque.chequeNumber} className={cx(styles['table__data-row'])}>
                    <td
                      className={cx(styles['table__data-cell'])}
                      data-label={t('bayer-value.rebates.table.number')}
                    >
                      {cheque.chequeNumber}
                    </td>
                    <td
                      className={cx(styles['table__data-cell'])}
                      data-label={t('bayer-value.rebates.table.date')}
                    >
                      {dayjs(cheque.chequeDate).format(slashSeparatedDate)}
                    </td>
                    <td
                      className={cx(styles['table__data-cell'])}
                      data-label={t('bayer-value.rebates.table.payee')}
                    >
                      {cheque.payee}
                    </td>
                    <td
                      className={cx(styles['table__data-cell'], styles['table__data-cell--right'])}
                      data-label={t('bayer-value.rebates.table.value')}
                    >
                      {t('format.price', { value: cheque.totalPaymentDollars })}
                    </td>

                    <td
                      className={cx(styles['table__data-cell'], styles['table__data-cell--right'])}
                      data-label={t('bayer-value.rebates.table.status')}
                    >
                      {cheque.chequeStatus !== ChequeStatus.Unknown && (
                        <Pill
                          text={mapChequeStatus(cheque.chequeStatus, t)}
                          color={PillColor.WHITE}
                          size={PillSizes.SM}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        )}
      </div>

      <hr className={cx(styles['cheques-section__divider'])} />

      <div className={cx(styles['cheques-section__wrapper'])}>
        <div className={cx(styles['cheques-section__header'])}>
          <div>
            <h2 className={cx(styles['cheques-section__title'])}>
              <Trans i18nKey="bayer-value.rebate-statements.heading" values={{ year }} />
            </h2>
            <p className={cx(styles['cheques-section__sub-title'])}>
              {t('bayer-value.rebate-statements.description')}
            </p>
          </div>
        </div>

        <div className={cx(styles['cheques-section__tabs-wrapper'])}>
          <Tabs
            tabs={[
              {
                id: 'overview',
                name: t('bayer-value.rebate-statements.tabs.overview'),
                component: (
                  <PaginationProvider>
                    <RebateOverview year={year} />
                  </PaginationProvider>
                ),
              },
              {
                id: 'details',
                name: t('bayer-value.rebate-statements.tabs.details'),
                component: (
                  <PaginationProvider>
                    <RebateDetails year={year} />
                  </PaginationProvider>
                ),
              },
            ]}
          />
        </div>
      </div>
    </section>
  );
};

ChequesSection.displayName = 'ChequesSection';

export default ChequesSection;
