import {
  abbrevMonthOrdinalDayYearEn,
  abbrevMonthOrdinalDayYearFr,
} from '../../../../lib/constants/date-formats';
import dayjs, { getRewardEarningPeriodDate } from '../../../../lib/utils/dates';
import { FC, useMemo } from 'react';
import Pill, { PillColor, PillSizes } from '../../../_shared/Pill';

import cx from 'classnames';
import styles from './RewardsSection.module.scss';
import { useDateFormat } from '../../../../lib/hooks/useDateFormat';
import { useTranslation } from 'react-i18next';

interface Props {
  year: number;
}

const NoRewardsSection: FC<Props> = ({ year }) => {
  const { t } = useTranslation();
  const dateFormat = useDateFormat(abbrevMonthOrdinalDayYearEn, abbrevMonthOrdinalDayYearFr);
  const rewardsPeriod = getRewardEarningPeriodDate(year);

  const isOpen = useMemo(() => dayjs().isBefore(rewardsPeriod.end), [rewardsPeriod.end]);

  return (
    <div className={cx(styles['rewards-section'])}>
      <p className={cx(styles['rewards-section__no-rewards-title'])}>
        <span
          className={cx(
            styles['rewards-section__text'],
            styles['rewards-section__text--disclaimer']
          )}
        >
          {t('punctuation.colon', {
            value: t('bayer-value.program-status'),
          })}
        </span>{' '}
        <Pill
          as="span"
          text={isOpen ? t('general.open') : t('general.closed')}
          size={PillSizes.SM}
          color={isOpen ? PillColor.GREEN : PillColor.RED}
        />
      </p>
      <p className={cx(styles['rewards-section__text'], styles['rewards-section__text--overline'])}>
        {t('bayer-value.total-rewards-earned-period', {
          start: dayjs(rewardsPeriod.start).format(dateFormat),
          end: dayjs(rewardsPeriod.end).format(dateFormat),
        })}
      </p>
    </div>
  );
};

export default NoRewardsSection;
