import {
  AddIcon,
  CheckmarkIcon,
  ChevronLeftIcon,
  EditIcon,
  FileDownloadIcon,
  InfoOutlineIcon,
  TrashCanIcon,
} from '../../../../assets/icons';
import { ButtonThemes, IconPosition } from '../../../../lib/constants/components';
import {
  calculationToDownloadFormat,
  generateCalculationName,
} from '../../../../lib/utils/calculator';
import {
  CalculatorAction,
  ProductMainGroup,
  ResultsChartColor,
  RewardsDetails,
} from '../../../../lib/types/calculator';
import { CalculatorStep, type StepComponentProps } from '../../../../lib/constants/stepper';
import {
  CREATE_CALCULATION,
  DELETE_CALCULATION,
  DELETE_CALCULATION_LIST,
  EDIT_CALCULATION,
  GET_SAVED_CALCULATION_NAMES,
  GET_SAVED_CALCULATIONS,
} from '../../../../lib/graphql/Calculator.gql';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { fullDateEn, fullDateFr } from '../../../../lib/constants/date-formats';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { BayerValueEastRuleId } from '../../rules/east';
import { BayerValueWestRuleId } from '../../rules/west';
import Button from '../../../../components/_shared/Button';
import { CalculatorContextActionTypes } from '../../../../lib/contexts/calculator/CalculatorContext.types';
import ContextMenu from '../../../../components/_shared/ContextMenu';
import cx from 'classnames';
import dayjs from 'dayjs';
import DeleteCalculationModal from '../../modals/DeleteCalculationModal';
import { downloadFile } from '../../../../lib/utils/api';
import { expandedCropList } from '../../../../lib/constants/calculator';
import ExportClient from '../../../../lib/clients/exports-client';
import { getRewardsPeriodYear } from '../../../../lib/utils/dates';
import { GraphQLErrorCodes } from '../../../../lib/constants/errorCodes';
import InfoBanner from '../../../../components/_shared/InfoBanner';
import { Language } from '../../../../lib/constants/i18n';
import Link from '../../../../components/_shared/Link';
import MaxCalculationsModal from '../../modals/MaxCalculationsModal';
import { MimeType } from '../../../../lib/constants/exports';
import ProgramDoughnutChart from '../../../../components/_shared/ProgramDoughnutChart';
import SaveCalculationModal from '../../modals/SaveCalculationModal';
import { SavedCalculation } from '../../../../__generated__/graphql';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import styles from './Results.module.scss';
import { toNormalizeTranslationKey } from '../../../../lib/utils/utils';
import Tooltip from '../../../../components/_shared/Tooltip';
import { useBreakpoint } from '../../../../lib/hooks';
import useCalculatorContext from '../../../../lib/contexts/calculator/useCalculatorContext';
import { useDateFormat } from '../../../../lib/hooks/useDateFormat';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

const Results: FC<StepComponentProps> = ({ goToStep, back }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { isXs } = useBreakpoint();
  const programYear = getRewardsPeriodYear();
  const variables = { input: { programYear } };

  // Context State
  const [
    {
      id,
      name,
      lastUpdated,
      selectedProducts,
      totalRewards,
      breakdown,
      totalCost,
      isSavedCalculation,
      region,
    },
    dispatchCalculator,
  ] = useCalculatorContext();
  const [, dispatchSnackbar] = useSnackbarContext();
  const dateFormat = useDateFormat(fullDateEn, fullDateFr);

  // Page States
  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [maxCalculationsModalOpen, setMaxCalculationsModalOpen] = useState<boolean>(false);
  const [maxCalculationsModalLoading, setMaxCalculationsModalLoading] = useState<boolean>(false);
  const [savedCalculations, setSavedCalculations] = useState<SavedCalculation[]>([]);
  const [actionMode, setActionMode] = useState<CalculatorAction>(CalculatorAction.SAVE);
  const [tempCalculationName, setTempCalculationName] = useState<string>('');
  const [calculationDate, setCalculationDate] = useState<string>(
    dayjs(lastUpdated).format(dateFormat)
  );

  // Queries / Mutations
  const { data: existingCalculationsNames, loading: loadingCalculationsNames } = useQuery(
    GET_SAVED_CALCULATION_NAMES,
    { variables }
  );
  const [getSavedCalculations, { loading: loadingCalculations }] = useLazyQuery(
    GET_SAVED_CALCULATIONS,
    { variables }
  );
  const [createCalculation, { loading: creatingCalculation }] = useMutation(CREATE_CALCULATION, {
    refetchQueries: [{ query: GET_SAVED_CALCULATIONS, variables }],
  });
  const [editCalculation, { loading: editingCalculation }] = useMutation(EDIT_CALCULATION, {
    refetchQueries: [{ query: GET_SAVED_CALCULATIONS, variables }],
  });
  const [deleteCalculation, { loading: deletingCalculation }] = useMutation(DELETE_CALCULATION, {
    refetchQueries: [{ query: GET_SAVED_CALCULATIONS, variables }],
  });
  const [deleteCalculationList] = useMutation(DELETE_CALCULATION_LIST, {
    refetchQueries: [{ query: GET_SAVED_CALCULATIONS, variables }],
  });

  // Local State
  const [calculationName, setCalculationName] = useState<string>(
    t('bayer-rewards-calculator.results.calculation.default-name', { date: dayjs() })
  );

  const loading = useMemo(
    () => loadingCalculations || editingCalculation || creatingCalculation || deletingCalculation,
    [editingCalculation, loadingCalculations, creatingCalculation, deletingCalculation]
  );

  useEffect(() => {
    setCalculationDate(dayjs(lastUpdated).format(dateFormat));
  }, [dateFormat, lastUpdated]);

  useEffect(() => {
    if (!loadingCalculationsNames) {
      setCalculationName(
        name ??
          generateCalculationName(
            existingCalculationsNames?.savedCalculations.map((calc) => calc.name) || [],
            calculationDate,
            t
          )
      );
    }
  }, [name, calculationDate, existingCalculationsNames, loadingCalculationsNames, language, t]);

  const onCloseRenameModal = () => {
    setActionMode(CalculatorAction.SAVE);
    setSaveModalOpen(false);
  };

  const onCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const onDeleteCalculation = () => {
    setDeleteModalOpen(true);
  };

  const handleError = () => {
    dispatchSnackbar({
      type: SnackbarContextActionTypes.AddToQueue,
      payload: { label: t('errors.generic'), state: SnackbarStates.WARNING },
    });
  };

  const handleNewCalculation = () => {
    dispatchCalculator({
      type: CalculatorContextActionTypes.ResetCalculator,
    });
    goToStep?.(CalculatorStep.INSTRUCTIONS);
  };

  const onNewCalculation = () => {
    if (!id) {
      setActionMode(CalculatorAction.SAVE_BEFORE_LEAVE);
      setSaveModalOpen(true);
    } else {
      handleNewCalculation();
    }
  };

  const handleCreateCalculation = (newCalculationName: string) => {
    const products = selectedProducts.map(({ acres, sku }) => ({ acres, sku }));

    createCalculation({
      variables: {
        input: {
          calculation: { selectedProducts: products, totalRewards },
          name: newCalculationName,
          programYear,
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          dispatchCalculator({
            type: CalculatorContextActionTypes.UpdateCalculation,
            payload: {
              id: data.createCalculation.id,
              name: data.createCalculation.name,
              date: data.createCalculation.lastUpdate,
            },
          });
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('bayer-rewards-calculator.results.save-calculation.success-snack'),
              state: SnackbarStates.SUCCESS,
            },
          });
        }
      })
      .catch(async (error) => {
        if (error?.graphQLErrors?.[0]?.extensions?.code === GraphQLErrorCodes.CALCULATION_LIMIT) {
          setTempCalculationName(newCalculationName);
          setMaxCalculationsModalLoading(true);
          await getSavedCalculations().then((calculationsData) => {
            setSavedCalculations(calculationsData?.data?.savedCalculations ?? []);
            setMaxCalculationsModalLoading(false);
            setMaxCalculationsModalOpen(true);
          });
        } else {
          handleError();
        }
      })
      .finally(() => {
        if (actionMode === CalculatorAction.SAVE_BEFORE_LEAVE) {
          handleNewCalculation();
        }
        onCloseRenameModal();
      });
  };

  const handleDeleteCalculations = (calculations: string[]) => {
    setMaxCalculationsModalLoading(true);
    deleteCalculationList({
      variables: { input: { ids: calculations } },
    })
      .then(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('bayer-rewards-calculator.saved-calculations.calculation-deleted'),
            state: SnackbarStates.SUCCESS,
          },
        });
      })
      .catch(handleError)
      .finally(() => {
        setMaxCalculationsModalLoading(false);
        setMaxCalculationsModalOpen(false);
        setSaveModalOpen(false);
        handleCreateCalculation(tempCalculationName);
      });
  };

  const handleEditName = (newCalculationName: string) => {
    if (!id) return;

    editCalculation({
      variables: { input: { id, name: newCalculationName } },
    })
      .then(({ data }) => {
        if (data) {
          dispatchCalculator({
            type: CalculatorContextActionTypes.UpdateCalculation,
            payload: {
              id: data.updateCalculation.id,
              name: data.updateCalculation.name,
              date: data.updateCalculation.lastUpdate,
            },
          });
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('bayer-rewards-calculator.results.save-calculation.success-snack'),
              state: SnackbarStates.SUCCESS,
            },
          });
        }
      })
      .catch(handleError)
      .finally(() => {
        onCloseRenameModal();
      });
  };

  const handleEditCalculation = () => {
    if (!id) return;

    const products = selectedProducts.map(({ acres, sku }) => ({ acres, sku }));

    editCalculation({
      variables: {
        input: {
          id,
          calculation: { selectedProducts: products, totalRewards },
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          dispatchCalculator({
            type: CalculatorContextActionTypes.UpdateCalculation,
            payload: {
              id: data.updateCalculation.id,
              name: data.updateCalculation.name,
              date: data.updateCalculation.lastUpdate,
            },
          });
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('bayer-rewards-calculator.results.save-calculation.success-snack'),
              state: SnackbarStates.SUCCESS,
            },
          });
        }
      })
      .catch(handleError);
  };

  const handleDeleteCalculation = () => {
    if (!id) {
      return;
    }

    deleteCalculation({
      variables: { input: { id } },
    })
      .then(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('bayer-rewards-calculator.saved-calculations.calculation-deleted'),
            state: SnackbarStates.SUCCESS,
          },
        });
        goToStep?.(CalculatorStep.SAVED_CALCULATIONS);
      })
      .catch(handleError)
      .finally(() => {
        dispatchCalculator({
          type: CalculatorContextActionTypes.ResetCalculator,
        });
        setDeleteModalOpen(false);
      });
  };

  const handleDownloadResults = () => {
    if (region && (name || calculationName)) {
      const { products, fieldViewQualifier } = calculationToDownloadFormat(
        breakdown,
        selectedProducts,
        language as Language,
        t
      );

      ExportClient.downloadCalculation(products, fieldViewQualifier, region, language as Language)
        .then(({ data }) => {
          downloadFile(data, name ?? calculationName, MimeType.XLSX);
        })
        .catch(() => {
          dispatchSnackbar({
            type: SnackbarContextActionTypes.AddToQueue,
            payload: {
              label: t('errors.generic'),
              state: SnackbarStates.WARNING,
            },
          });
        });
    }
  };

  const renderTableComponent = (rewardKey: string, details: RewardsDetails): ReactNode => (
    <table className={styles['table']}>
      <thead className={cx({ 'sr-only': isXs })}>
        <tr>
          <th className={styles['table__header']}>
            {t('bayer-rewards-calculator.results.breakdown-table.header.category')}
          </th>
          <th className={styles['table__header']}>
            {t('bayer-rewards-calculator.results.breakdown-table.header.product')}
          </th>
          <th className={styles['table__header']}>
            {t('bayer-rewards-calculator.results.breakdown-table.header.crop')}
          </th>
          <th className={styles['table__header']}>
            {t('bayer-rewards-calculator.results.breakdown-table.header.acres')}
          </th>
          <th className={styles['table__header']}>
            {t('bayer-rewards-calculator.results.breakdown-table.header.estimated-reward')}
          </th>
        </tr>
      </thead>
      <tbody className={styles['table__tbody']}>
        {details.products.map((product) => (
          <tr key={`${rewardKey}-${product.sku}`} className={styles['table__data-row']}>
            <td
              className={styles['table__data-cell']}
              data-label={t('bayer-rewards-calculator.results.breakdown-table.header.category')}
            >
              {t(`product.main-group.${toNormalizeTranslationKey(product.group)}`)}
            </td>
            <td
              className={styles['table__data-cell']}
              data-label={t('bayer-rewards-calculator.results.breakdown-table.header.product')}
            >
              {language === Language.En ? product.name : product.frenchName}
            </td>
            <td
              className={styles['table__data-cell']}
              data-label={t('bayer-rewards-calculator.results.breakdown-table.header.crop')}
            >
              {expandedCropList(t).find((crop) => crop.value === product.crop)?.label ?? ''}
            </td>
            <td
              className={styles['table__data-cell']}
              data-label={t('bayer-rewards-calculator.results.breakdown-table.header.acres')}
            >
              {product.acres}
            </td>
            <td
              className={styles['table__data-cell']}
              data-label={t('bayer-rewards-calculator.results.breakdown-table.header.acres')}
            >
              {t('format.price', {
                value: product.estimatedProductReward ?? 0,
              })}
            </td>
          </tr>
        ))}
        <tr className={styles['table__data-row']}>
          <td
            colSpan={4}
            className={cx(styles['table__data-cell'], styles['table__bottom-header'])}
          >
            {t('bayer-rewards-calculator.results.breakdown-table.total-rewards')}
          </td>
          <td className={cx(styles['table__data-cell'], styles['table__bottom-header'])}>
            {t('format.price', {
              value: details.reward,
            })}
          </td>
        </tr>
      </tbody>
    </table>
  );

  const renderRewardComponent = (rewardKey: string, details: RewardsDetails): ReactNode => {
    if (
      rewardKey === BayerValueWestRuleId.FIELD_VIEW ||
      rewardKey === BayerValueEastRuleId.FIELD_VIEW
    ) {
      return (
        <div>
          <InfoBanner
            title={t('bayer-rewards-calculator.results.special-conditions.field-view.header')}
            closeButton={false}
            icon={CheckmarkIcon}
            content={t('bayer-rewards-calculator.results.special-conditions.field-view.content')}
          />

          <dl className={styles['description-list']}>
            <dt className={styles['description-list__label']}>
              {t('bayer-rewards-calculator.results.breakdown-table.total-rewards')}
            </dt>
            <dd className={styles['description-list__value']}>
              {t('format.price', {
                value: details.reward,
              })}
            </dd>
          </dl>
        </div>
      );
    }

    return renderTableComponent(rewardKey, details);
  };

  const renderRewardSection = (rewardKey: string, details: RewardsDetails): ReactNode => (
    <div key={rewardKey} className={styles['results__table-container']}>
      <div className={styles['results__table-title-wrapper']}>
        {details.programUrl ? (
          <Link
            href={details.programUrl}
            target="_blank"
            altText={t('bayer-value.climate-fieldview.cta-alt')}
            rel="noopener noreferrer"
            className={styles['results__table-title']}
          >
            {t(`bayer-rewards-calculator.results.component.${rewardKey}`)}
          </Link>
        ) : (
          <span className={styles['results__table-title']}>
            {t(`bayer-rewards-calculator.results.component.${rewardKey}`)}
          </span>
        )}
      </div>

      {renderRewardComponent(rewardKey, details)}
    </div>
  );

  return (
    <div className={styles['results']}>
      {saveModalOpen && (
        <SaveCalculationModal
          isLoading={creatingCalculation || editingCalculation}
          isOpen={saveModalOpen}
          mode={actionMode}
          onClose={onCloseRenameModal}
          onSave={handleCreateCalculation}
          onEdit={handleEditName}
          defaultValue={
            actionMode === CalculatorAction.SAVE_AS_NEW ||
            actionMode === CalculatorAction.SAVE_BEFORE_LEAVE
              ? generateCalculationName(
                  existingCalculationsNames?.savedCalculations.map((calc) => calc.name) || [],
                  calculationDate,
                  t
                )
              : calculationName
          }
        />
      )}
      {deleteModalOpen && (
        <DeleteCalculationModal
          isOpen={deleteModalOpen}
          onClose={onCloseDeleteModal}
          onDelete={handleDeleteCalculation}
        />
      )}
      {maxCalculationsModalOpen && (
        <MaxCalculationsModal
          isLoading={maxCalculationsModalLoading}
          isOpen={maxCalculationsModalOpen}
          onClose={() => setMaxCalculationsModalOpen(false)}
          onSave={handleDeleteCalculations}
          savedCalculations={savedCalculations}
        />
      )}
      <div className={styles['results__buttons-wrapper']}>
        <Button
          className={styles['results__back-button']}
          disabled={loading}
          onClick={() => {
            if (isSavedCalculation) {
              dispatchCalculator({
                type: CalculatorContextActionTypes.ResetCalculator,
              });
              goToStep?.(CalculatorStep.SAVED_CALCULATIONS);
            } else {
              back?.();
            }
          }}
          theme={ButtonThemes.SECONDARY}
          iconPosition={IconPosition.LEFT}
          icon={ChevronLeftIcon}
        >
          {isSavedCalculation
            ? t('bayer-rewards-calculator.results.actions.back-to-calculations')
            : t('bayer-rewards-calculator.results.actions.back-to-calculator')}
        </Button>
        <div className={styles['results__actions-wrapper']}>
          {id ? (
            <>
              <ContextMenu
                options={[
                  {
                    label: t('general.save'),
                    action: handleEditCalculation,
                  },
                  {
                    label: t('bayer-rewards-calculator.results.actions.save-new'),
                    action: () => {
                      setActionMode(CalculatorAction.SAVE_AS_NEW);
                      setSaveModalOpen(true);
                    },
                  },
                ]}
                cta={{
                  label: t('general.save'),
                  disabled: loading,
                  buttonClassName: styles['results__context-menu'],
                  buttonTheme: ButtonThemes.PRIMARY,
                  action: () => {},
                }}
              />
              {isSavedCalculation && (
                <Button
                  theme={ButtonThemes.SECONDARY}
                  iconPosition={IconPosition.LEFT}
                  disabled={loading}
                  onClick={back}
                  icon={EditIcon}
                >
                  {t('general.edit')}
                </Button>
              )}
              <Button
                theme={ButtonThemes.SECONDARY}
                iconPosition={IconPosition.LEFT}
                disabled={loading}
                onClick={onDeleteCalculation}
                icon={TrashCanIcon}
              >
                {t('general.delete')}
              </Button>
            </>
          ) : (
            <Button
              disabled={loading}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            >
              {t('general.save')}
            </Button>
          )}

          <Button
            theme={ButtonThemes.SECONDARY}
            iconPosition={IconPosition.LEFT}
            icon={AddIcon}
            disabled={loading}
            onClick={onNewCalculation}
          >
            {t('bayer-rewards-calculator.results.actions.new-calculation')}
          </Button>
        </div>
      </div>

      <div className={styles['results__wrapper']}>
        <div>
          <div className={styles['results__title-wrapper']}>
            <h1 className={styles['results__title']}>{calculationName}</h1>
            {id && (
              <Button
                icon={EditIcon}
                theme={ButtonThemes.SECONDARY}
                iconPosition={IconPosition.CENTER}
                className={styles['results__edit-button']}
                disabled={loading}
                onClick={() => {
                  setActionMode(CalculatorAction.EDIT);
                  setSaveModalOpen(true);
                }}
              >
                <span className="sr-only">{t('general.edit')}</span>
              </Button>
            )}
          </div>

          <p className={cx(styles['results__sub-text'])}>
            {t('bayer-rewards-calculator.results.calculation.last-updated', {
              date: calculationDate,
            })}
          </p>
        </div>

        {breakdown && (
          <div className={cx(styles['results__chart'])}>
            <div className={cx(styles['results__chart-legend'])}>
              <div className={cx(styles['results__chart-title'])}>
                <span className={cx(styles['results__chart-title-text'])}>
                  {t('bayer-rewards-calculator.results.chart.title')}
                </span>
                <span className={styles['results__chart-title-amount']}>
                  {t('format.price', { value: totalRewards })}
                </span>
              </div>
              <span className={styles['results__chart-legend-title']}>
                {t('bayer-rewards-calculator.results.chart.legend-title')}
              </span>
              {Object.keys(breakdown).map(
                (rewardKey, index) =>
                  rewardKey !== ProductMainGroup.TRAIT && (
                    <div className={styles['results__chart-legend-item']} key={rewardKey}>
                      <div
                        aria-hidden
                        className={styles['results__chart-legend-indicator']}
                        style={{ backgroundColor: ResultsChartColor[index] }}
                      />
                      <span className={styles['results__chart-legend-text']}>
                        {t(`bayer-rewards-calculator.results.component.${rewardKey}`)}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className={styles['results__chart-container']}>
              <div className={styles['results__chart-wrapper']}>
                <ProgramDoughnutChart
                  data={Object.entries(breakdown).map(([rewardKey, details], index) => ({
                    color: ResultsChartColor[index],
                    amount: details.reward,
                    programName: t(`bayer-rewards-calculator.results.component.${rewardKey}`),
                  }))}
                  label={t('bayer-value.breakdown.rewards-earned')}
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles['results__table-container']}>
          <div className={styles['results__table-title-wrapper']}>
            <span className={styles['results__table-title']}>
              {t('bayer-rewards-calculator.results.component.products-included')}
            </span>
            <div className={styles['results__table-actions']}>
              {/* <Button theme={ButtonThemes.TEXT_LINK} icon={PrintIcon} onClick={handlePrintResults}>
                {t('bayer-rewards-calculator.results.print')}
              </Button>  */}
              <Button
                theme={ButtonThemes.TEXT_LINK}
                icon={FileDownloadIcon}
                onClick={handleDownloadResults}
                className="no-print"
              >
                {t('bayer-rewards-calculator.results.actions.download')}
              </Button>
            </div>
          </div>
          <table className={styles['table']}>
            <thead className={cx({ 'sr-only': isXs })}>
              <tr>
                <th className={styles['table__header']}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.category')}
                </th>
                <th className={styles['table__header']}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.product')}
                </th>
                <th className={styles['table__header']}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.crop')}
                </th>
                <th className={styles['table__header']}>
                  {t('bayer-rewards-calculator.results.breakdown-table.header.acres')}
                </th>
                <th className={styles['table__header']}>
                  <span className={styles['results__tooltip-wrapper']}>
                    {t('bayer-rewards-calculator.results.breakdown-table.header.estimated-cost')}
                    <Tooltip
                      text={t(
                        'bayer-rewards-calculator.results.breakdown-table.estimated-cost-tooltip'
                      )}
                      className={styles['results__tooltip']}
                    >
                      <InfoOutlineIcon type="button" className={styles['results__tooltip-icon']} />
                    </Tooltip>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className={styles['table__tbody']}>
              {selectedProducts.map((selectedProduct) => (
                <tr
                  key={`total-breakdown-${selectedProduct.sku}`}
                  className={styles['table__data-row']}
                >
                  <td
                    className={styles['table__data-cell']}
                    data-label={t(
                      'bayer-rewards-calculator.results.breakdown-table.header.category'
                    )}
                  >
                    {t(`product.main-group.${toNormalizeTranslationKey(selectedProduct.group)}`)}
                  </td>
                  <td
                    className={styles['table__data-cell']}
                    data-label={t(
                      'bayer-rewards-calculator.results.breakdown-table.header.product'
                    )}
                  >
                    {language === Language.En ? selectedProduct.name : selectedProduct.frenchName}
                  </td>
                  <td
                    className={styles['table__data-cell']}
                    data-label={t('bayer-rewards-calculator.results.breakdown-table.header.crop')}
                  >
                    {expandedCropList(t).find((crop) => crop.value === selectedProduct.crop)
                      ?.label ?? ''}
                  </td>
                  <td
                    className={styles['table__data-cell']}
                    data-label={t('bayer-rewards-calculator.results.breakdown-table.header.acres')}
                  >
                    {selectedProduct.acres}
                  </td>
                  <td
                    className={styles['table__data-cell']}
                    data-label={t(
                      'bayer-rewards-calculator.results.breakdown-table.header.estimated-cost'
                    )}
                  >
                    {t('format.price', {
                      value: selectedProduct.acres * selectedProduct.pricePerAcre,
                    })}
                  </td>
                </tr>
              ))}
              <tr className={styles['table__data-row']}>
                <td
                  colSpan={4}
                  className={cx(styles['table__data-cell'], styles['table__bottom-header'])}
                >
                  {t('bayer-rewards-calculator.results.breakdown-table.total-cost')}
                </td>
                <td className={cx(styles['table__data-cell'], styles['table__bottom-header'])}>
                  {t('format.price', {
                    value: totalCost,
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {Object.entries(breakdown).map(([rewardKey, details]) =>
          renderRewardSection(rewardKey, details)
        )}
      </div>
    </div>
  );
};

Results.displayName = 'Results';

export default Results;
