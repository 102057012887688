import { ButtonThemes, EmptyStateTheme } from '../../../lib/constants/components';
import { AllProgramTab } from '../../../lib/constants/programs';
import Button from '../../../components/_shared/Button';
import ContactUs from '../../../components/BayerValue/ContactUs';
import { FC } from 'react';
import { gotToTab } from '../../../components/_shared/Tabs/Tabs.utils';
import { PlaylistAddIcon } from '../../../assets/icons';
import styles from './AllLoyaltyPrograms.module.scss';
import { Tab } from '../../../components/_shared/Tabs/Tabs.types';
import TableEmptyState from '../../../components/TableEmptyState';
import Tabs from '../../../components/_shared/Tabs';
import { usePageTitle } from '../../../lib/hooks/usePageTitle';
import { useTranslation } from 'react-i18next';

const AllLoyaltyPrograms: FC = () => {
  usePageTitle('all-loyalty-programs');
  const { t } = useTranslation();
  const renderEmptyStates = (id: AllProgramTab) => (
    <div className={styles['all-programs__empty-state-wrapper']}>
      {id === AllProgramTab.UNENROLLED ? (
        <TableEmptyState
          Icon={PlaylistAddIcon}
          title={t('all-programs.empty-state.unenrolled.title')}
          description={t('all-programs.empty-state.unenrolled.description')}
          theme={EmptyStateTheme.SECONDARY}
        />
      ) : (
        <>
          <TableEmptyState
            Icon={PlaylistAddIcon}
            title={t('all-programs.empty-state.enrolled.title')}
            description={t('all-programs.empty-state.enrolled.description')}
            theme={EmptyStateTheme.SECONDARY}
          />
          <Button theme={ButtonThemes.SECONDARY} onClick={() => gotToTab(AllProgramTab.UNENROLLED)}>
            {t('all-programs.empty-state.enrolled.cta')}
          </Button>
        </>
      )}
    </div>
  );
  const tabs: Tab[] = [
    {
      name: t('general.unenrolled'),
      id: AllProgramTab.UNENROLLED,
      disabled: false,
      component: renderEmptyStates(AllProgramTab.UNENROLLED),
    },
    {
      name: t('general.enrolled'),
      id: AllProgramTab.ENROLLED,
      disabled: false,
      component: renderEmptyStates(AllProgramTab.ENROLLED),
    },
  ];

  return (
    <div className={styles['all-programs']}>
      <div className={styles['all-programs__wrapper']}>
        <section className={styles['all-programs__hero']}>
          <h1 className={styles['all-programs__heading']}>{t('all-programs.heading')}</h1>
          <p className={styles['all-programs__sub-heading']}>{t('all-programs.description')}</p>
        </section>
        <Tabs tabs={tabs} />
      </div>
      <ContactUs />
    </div>
  );
};

AllLoyaltyPrograms.displayName = 'AllLoyaltyPrograms';

export default AllLoyaltyPrograms;
