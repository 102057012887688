import { EditIcon, SpinnerIcon } from '../../../../assets/icons';

import { ButtonThemes } from '../../../../lib/constants/components';
import { CalculatorAction } from '../../../../lib/types/calculator';
import cx from 'classnames';
import { FC } from 'react';
import Modal from '../../../../components/_shared/Modal';
import styles from '../CalculationModals.module.scss';
import TextField from '../../../../components/_shared/TextField';
import { useForm } from 'react-hook-form';
import { useFormErrors } from '../../../../lib/hooks/useFormErrors';
import { useTranslation } from 'react-i18next';

export interface Props {
  isOpen: boolean;
  isLoading: boolean;
  mode: CalculatorAction;
  defaultValue?: string;
  onClose: () => void;
  onSave?: (newCalculationName: string) => void;
  onEdit?: (newCalculationName: string) => void;
}

const SaveCalculationModal: FC<Props> = ({
  isOpen,
  isLoading,
  mode,
  defaultValue,
  onClose,
  onSave,
  onEdit,
}) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { dirtyFields, errors, isValid },
    trigger,
    watch,
  } = useForm<{ name: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      name: defaultValue,
    },
  });
  useFormErrors(trigger, dirtyFields);
  const calculationName = watch('name');

  const getModalTitle = (): string => {
    switch (mode) {
      case CalculatorAction.EDIT:
        return t('bayer-rewards-calculator.results.save-calculation.rename');
      case CalculatorAction.SAVE:
      default:
        return t('bayer-rewards-calculator.results.save-calculation.save');
    }
  };

  const getModalAction = () => {
    switch (mode) {
      case CalculatorAction.EDIT:
        return onEdit?.(calculationName);
      case CalculatorAction.SAVE:
      default:
        return onSave?.(calculationName);
    }
  };

  return (
    <Modal
      isVisible={isOpen}
      hide={onClose}
      title={getModalTitle()}
      primaryCta={{
        label: t('general.save'),
        action: () => getModalAction(),
        disabled: !isValid || isLoading,
        buttonIcon: isLoading ? SpinnerIcon : undefined,
        buttonIconClassName: cx({
          [styles['calculator-modals__spinner']]: isLoading,
        }),
      }}
      secondaryCta={{
        buttonTheme: ButtonThemes.SECONDARY,
        disabled: isLoading,
        label: t('general.cancel'),
        action: onClose,
      }}
    >
      <form className={styles['calculator-modals__container']}>
        {(mode === CalculatorAction.SAVE || mode === CalculatorAction.SAVE_AS_NEW) && (
          <p className={styles['calculator-modals__text']}>
            {t('bayer-rewards-calculator.results.save-calculation.save-description')}
          </p>
        )}
        {mode === CalculatorAction.SAVE_BEFORE_LEAVE && (
          <div>
            <p
              className={cx(
                styles['calculator-modals__text'],
                styles['calculator-modals__text--bold']
              )}
            >
              {t('bayer-rewards-calculator.results.save-calculation.save-leaving')}
            </p>
            <p className={styles['calculator-modals__text']}>
              {t('bayer-rewards-calculator.results.save-calculation.save-leaving-description')}
            </p>
          </div>
        )}
        <TextField
          className={cx(styles['form-input'], {
            [styles['form-input--error']]: !!errors.name,
          })}
          icon={EditIcon}
          inputType="text"
          label={t('form.labels.calculation-name')}
          {...register('name', {
            required: t('form.errors.required'),
          })}
          hasError={!!errors.name}
          helperText={errors.name?.message}
        />
      </form>
    </Modal>
  );
};

SaveCalculationModal.displayName = 'SaveCalculationModal';

export default SaveCalculationModal;
