import Pill, { PillColor, PillSizes } from '../Pill';
import {
  ProgramCardBadgeAlignment,
  ProgramCardLayout,
  ProgramCardSize,
  Props,
} from './ProgramCard.types';

import cx from 'classnames';
import { FC } from 'react';
import ProgramCard from './ProgramCard';
import styles from './ProgramCard.module.scss';
import { useTranslation } from 'react-i18next';

const ProgramCardWithPill: FC<Props> = ({
  badgeAlignment = ProgramCardBadgeAlignment.TOP,
  description,
  layout = ProgramCardLayout.HORIZONTAL,
  program,
  size = ProgramCardSize.SM,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['program-card-pill'])}>
      <div>
        <ProgramCard
          badgeAlignment={badgeAlignment}
          description={description}
          layout={layout}
          program={program}
          size={size}
        />
      </div>
      <div className={cx(styles['program-card-pill__pill-wrapper'])}>
        <Pill text={t('general.enrolled')} color={PillColor.GREEN} size={PillSizes.SM} />
      </div>
    </div>
  );
};

ProgramCardWithPill.displayName = 'ProgramCardWithPill';

export default ProgramCardWithPill;
