import { CheckmarkValidationIcon, IndeterminateIcon } from '../../../assets/icons';
import { FC, forwardRef, ReactNode, SVGProps, useRef } from 'react';

import Avatar from '../Avatar';
import cx from 'classnames';
import styles from './Checkbox.module.scss';
import { v4 as uuid } from 'uuid';

export interface Props {
  id?: string;
  disabled?: boolean;
  label: string | ReactNode;
  indeterminate?: boolean;
  value: React.InputHTMLAttributes<HTMLInputElement>['value'];
  name: string;
  onChange(e?: any): void;
  className?: {
    wrapper?: string;
    label?: string;
    icon?: string;
    iconWrapper?: string;
  };
  hasError?: boolean;
  image?: string | FC<SVGProps<SVGSVGElement>>;
}

const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      id = undefined,
      disabled = false,
      indeterminate = false,
      value,
      label,
      name,
      onChange,
      className,
      hasError,
      image: Icon,
    },
    ref
  ) => {
    const checkboxId = useRef<string>(id || uuid());
    const labelId = useRef<string>(uuid());

    const renderImage = () => {
      if (!Icon) {
        return undefined;
      } else if (typeof Icon === 'string') {
        return <Avatar profilePictureUrl={Icon} firstName="" lastName="" />;
      }
      return (
        <div className={className?.iconWrapper}>
          <Icon className={className?.icon} />
        </div>
      );
    };

    return (
      <label
        className={cx(
          styles['checkbox'],
          {
            [styles['checkbox--with-image']]: Icon,
          },
          className?.wrapper
        )}
        htmlFor={checkboxId.current}
      >
        <div className={cx(styles['checkbox__circle'])}>
          <input
            className={cx(styles['checkbox__input'], 'sr-only')}
            type="checkbox"
            onChange={onChange}
            disabled={disabled}
            value={value}
            name={name}
            ref={ref}
            id={checkboxId.current}
            aria-label={indeterminate ? 'checkbox state is indeterminate' : ''}
            aria-describedby={labelId.current}
          />
          <span
            className={cx(styles['checkbox__box'], {
              [styles['checkbox__box--disabled']]: !indeterminate && disabled,
              [styles['checkbox__box--error']]: hasError,
            })}
          >
            {!indeterminate ? (
              <CheckmarkValidationIcon
                className={cx([styles['checkbox__check']], {
                  [styles['checkbox__check--disabled']]: disabled,
                  [styles['checkbox__check--error']]: hasError,
                })}
                aria-hidden="true"
              />
            ) : (
              <IndeterminateIcon
                className={cx(styles['checkbox__indeterminate'], {
                  [styles['checkbox__indeterminate--disabled']]: disabled,
                  [styles['checkbox__check--error']]: hasError,
                })}
                aria-hidden="true"
              />
            )}
          </span>
        </div>

        <span
          id={labelId.current}
          className={cx(
            styles['checkbox__label'],
            {
              [styles['checkbox__label--with-image']]: Icon,
            },
            className?.label
          )}
        >
          {renderImage()}
          {label}
        </span>
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
