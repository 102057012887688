export interface ContextMenuOption {
  label: string;
  action: () => void;
}

export enum ActionKey {
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
}

export enum ContextMenuPosition {
  LEFT = 'left',
  RIGHT = 'right',
}